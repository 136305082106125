export default function formatCents(cents, currency) {
  try {
    const parsed = Number.parseInt(cents, 10);
    if (Number.isNaN(parsed)) {
      return '';
    }
    const dollars = cents / 100;
    return Intl.NumberFormat('en', {
      style: 'currency',
      currency: currency,
    }).format(dollars);
  } catch (ex) {
    return '';
  }
}

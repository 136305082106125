import {
  selectCanShowPersonalizedAdsUnderGdpr,
  selectGdprApplies,
  selectIsOptedOutOfTargeting,
} from '../../selectors/app';

export function canShowPersonalizedAds(state) {
  return selectGdprApplies(state)
    ? selectCanShowPersonalizedAdsUnderGdpr(state)
    : !selectIsOptedOutOfTargeting(state);
}

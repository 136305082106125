import {
  countryCodes,
  countryStateCodes,
  unitedStatesTerritories,
} from '../constants/locale';

export default function isUserInUSOrUSTerritories(countryCode) {
  return (
    countryCode === countryCodes.US || !!unitedStatesTerritories[countryCode]
  );
}

export const isUserInCalifornia = (countryCode, countryStateCode) =>
  countryCode === countryCodes.US &&
  countryStateCode === countryStateCodes.california;

export const isUserInWashington = (countryCode, countryStateCode) =>
  countryCode === countryCodes.US &&
  countryStateCode === countryStateCodes.washington;

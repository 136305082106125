import { getProductsBySkus } from '../../actions/products';
import { fetchActiveCouponIfNeeded } from '../../actions/subscription';
import { COUPON_QUERY_PARAM } from '../../constants/queryParams';
import parse from '../queryString/parse';
import {
  getDefaultSkus,
  getSkusFromQuery,
} from '../subscription/findProductHelpers';

export default function fetchProducts(location, isRenew = false) {
  const query = parse(location?.search || '');
  const newLocation = { ...location, query };
  return async (dispatch, getState) => {
    const fromQuery = getSkusFromQuery(newLocation);
    const config = getState()?.config?.autoupdate;
    const defaultSkus = getDefaultSkus(newLocation, config, isRenew);
    const skusToUse = fromQuery.length ? fromQuery : defaultSkus;
    const couponCode = newLocation?.query?.[COUPON_QUERY_PARAM];
    const meta = { isRenew, defaultSkus, fromQuery };

    if (couponCode) {
      await dispatch(fetchActiveCouponIfNeeded(couponCode));
    }

    return await dispatch(getProductsBySkus(skusToUse, meta));
  };
}

import { isType } from '@tunein/web-common';
import { fetchTopicData } from 'src/common/actions/profile';
import { types as GuideItemTypes } from '../guideItemTypes';

// set up with:
// createRadioProfileContext in ProfileConnector
// createVanityProfileContext in VanityProfileConnector
// getRadioContext in RadioRouteConnector
export default function setupFetchTopic(routeContextCreator) {
  return function fetchTopic(props) {
    const guideItemRouteContext = routeContextCreator(props);
    if (!guideItemRouteContext) {
      return null;
    }

    const { guideId, topicGuideId } = guideItemRouteContext;
    if (
      isType(guideId, GuideItemTypes.station, GuideItemTypes.program) &&
      topicGuideId
    ) {
      return fetchTopicData(topicGuideId);
    }
    return null;
  };
}

import { setIsBranchInitialized } from '../../actions/app';
import {
  channel,
  homeCampaign,
  profileCampaign,
} from '../../constants/analytics/branch';
import { AB_TEST_IDS } from '../../constants/experiments';
import { TEXT_ME_THE_APP_ENABLED } from '../../constants/experiments/dialog';
import {
  ACCOUNT_NEW_RESET_PATH,
  AUTHORIZE_CONFIRM_PATH,
  AUTHORIZE_PATH,
  LOGIN_PATH,
  SUBSCRIBE_PATH,
} from '../../constants/paths';
import { selectExperiment } from '../../selectors/config';
import vars from '../../vars';
import getDeepLinkPath from './getDeepLinkPath';

// exported for testing
export const blockListedBranchRoutes = [
  LOGIN_PATH,
  SUBSCRIBE_PATH,
  AUTHORIZE_PATH,
  AUTHORIZE_CONFIRM_PATH,
  ACCOUNT_NEW_RESET_PATH,
];

export function getLinkMeta({
  guideId,
  serial,
  abTestIds,
  customCampaign,
  rootGenreClassification,
}) {
  const defaultCampaign = guideId ? profileCampaign : homeCampaign;
  const deepLinkPath = getDeepLinkPath(guideId);

  return {
    $deeplink_path: deepLinkPath,
    $android_deeplink_path: deepLinkPath, // Android requires this explicitly set for upsell deeplinks
    campaign: customCampaign || defaultCampaign,
    channel,
    user_id: serial,
    ab_test_id: abTestIds,
    ...(rootGenreClassification && { rootGenreClassification }),
  };
}

export function getFallbackLink(linkMetaData) {
  const url = new URL(`https://listen.tunein.com/a/${vars.get('BRANCH_KEY')}/`);
  url.search = new URLSearchParams(linkMetaData);
  return url.toString();
}

export function createBranchLink(options, setBranchUrl) {
  const linkMetaData = getLinkMeta(options);
  const fallbackLink = getFallbackLink(linkMetaData);

  window.branch.link(
    {
      data: linkMetaData,
    },
    (err, link) => {
      // If the request ever comes back and there is no link (this will most likey be if there's an
      // error of sorts with the branch API), let's use the fallback so we can still deeplink
      // regardless.
      if (link) {
        setBranchUrl(link);
      } else {
        setBranchUrl(fallbackLink);
      }
    },
  );
}

// options: { guideId, title, serial, abTestIds, customCampaign }
export function setBranchViewData(options) {
  window.branch.setBranchViewData({
    data: {
      ...getLinkMeta(options),
      referrer_unique_id: options.serial,
    },
  });
}

export function closeBranchJourney() {
  window.branch.closeJourney();
}

// this only reopens it if it was not closed by the user
export function reopenBranchJourney() {
  window.branch.track('pageview');
}

// listener event names: https://docs.branch.io/pages/web/integrate/#handle-listeners
export function addBranchListener(eventName, callback) {
  window.branch.addListener(eventName, callback);
}

// https://help.branch.io/developers-hub/docs/web-full-reference#removelistenerlistener
export function removeBranchListener(callback) {
  window.branch.removeListener(callback);
}

export function isBlocklistedBranchRoute(route) {
  return blockListedBranchRoutes.includes(route);
}

export function initBranch(store, route) {
  const state = store.getState();
  const { isMobile, isDiscord } = state.app;
  const isTextMeTheAppEnabled = selectExperiment(
    state,
    TEXT_ME_THE_APP_ENABLED,
  );

  if ((!isMobile && !isTextMeTheAppEnabled) || isDiscord) {
    return;
  }

  function onBranchSdkLoad() {
    const key = vars.get('BRANCH_KEY');
    const serial = state.app.tuneInUserSerial || '';

    const options = {
      metadata: {
        user_id: serial,
        ab_test_id: selectExperiment(state, AB_TEST_IDS),
        referrer_unique_id: serial,
      },
      no_journeys: isBlocklistedBranchRoute(route),
    };

    window.branch.init(key, options);
    window.branch.setIdentity(serial);
    store.dispatch(setIsBranchInitialized());
  }

  import(/* webpackChunkName: "branchSdk" */ 'branch-sdk')
    .then(onBranchSdkLoad)
    .catch(() => {});
}

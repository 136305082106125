import url from 'url';
import get from 'lodash/get';
import { selectIsAuthenticated } from 'src/common/selectors/auth';
import parseQuery from '../../utils/queryString/parse';
import { excludeUtmParamsFromLocationDescriptor } from '../utmCampaignParams';

const loginPathRegex = /login/;

export default function getLoginRedirect(auth, location, extraQuery) {
  const isLoginPath = loginPathRegex.test(location.pathname);

  const redirect = {
    ...location,
    // This util is used by top level route components where the location
    // object does not contain a query property. Instead, we are building
    // the location's query by parsing the search property so that any
    // extraQuery params can be added to it below.
    query: parseQuery(get(location, 'search', '')),
  };

  // extra query only gets added TO QUERY
  if (extraQuery) {
    redirect.query[extraQuery] = true;
  }

  if (!selectIsAuthenticated({ auth })) {
    if (!isLoginPath) {
      return {
        pathname: '/login/',
        query: {
          ...redirect.query, // need for things like paymentFlow, etc
          redirect: url.format(
            excludeUtmParamsFromLocationDescriptor(redirect),
          ),
        },
      };
    }
  } else {
    if (isLoginPath) {
      return {
        pathname: '/',
        query: redirect.query,
      };
    }
  }

  return undefined;
}

import {
  selectSubscriptionProviderName,
  selectUserDetails,
} from '../../selectors/me';
import { selectCurrentSubStatus } from '../../selectors/subscription';
import hasSubscribed from './hasSubscribed';
import subscribedThroughWeb from './subscribedThroughWeb';
import {
  currentSubscriptionStatus,
  premiumSubscriptionStatus,
} from './subscriptionStatus';

export default function getCanUpdateSub(state) {
  const providerName = selectSubscriptionProviderName(state);
  const hasPreviouslySubscribedOnWeb = subscribedThroughWeb(providerName);

  if (!hasPreviouslySubscribedOnWeb) {
    return false;
  }

  const currentSubStatus = selectCurrentSubStatus(state);
  const premiumSubStatus = selectCurrentSubStatus(state);
  const isNotExpiredPremium =
    premiumSubStatus !== premiumSubscriptionStatus.expired;
  const isNotCanceledOrExpired =
    !!currentSubStatus &&
    currentSubStatus !== currentSubscriptionStatus.canceled &&
    currentSubStatus !== currentSubscriptionStatus.expired &&
    isNotExpiredPremium;
  const userDetails = selectUserDetails(state);

  return hasSubscribed(userDetails) && isNotCanceledOrExpired;
}

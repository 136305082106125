import isEmpty from 'lodash/isEmpty';
import { fetchPartnersEffect } from '../../actions/partners';
import { selectPartners } from '../../selectors/partners';
import findPartnerInfo from '../findPartnerInfo';
import { getOauthPartnerId } from '../queryParams';

export default function fetchPartner({ location }) {
  return (dispatch, getState) => {
    const { query } = location || {};
    const id = getOauthPartnerId(query);
    const partners = selectPartners(getState());
    const partnerInfo = findPartnerInfo(query, partners);

    if (id && isEmpty(partnerInfo)) {
      return dispatch(fetchPartnersEffect(id));
    }

    return null;
  };
}

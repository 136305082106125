import { STRIPE_ENABLED } from '../../constants/experiments/subscription';
import { selectExperiment } from '../../selectors/config';
import {
  selectSubscriptionProviderName,
  selectSubscriptionStatus,
} from '../../selectors/me';
import { selectCoupon, selectHasCoupon } from '../../selectors/subscription';
import getSubscriptionProvider, { providers } from './getSubscriptionProvider';
import { premiumSubscriptionStatus } from './subscriptionStatus';

export default function shouldUseStripe(state) {
  const providerName = selectSubscriptionProviderName(state);
  const existingSubscriptionStatus = selectSubscriptionStatus(state);
  const provider = getSubscriptionProvider(providerName);
  const existingCouponInState = selectCoupon(state);
  const hasExistingCouponInState = selectHasCoupon(state);

  if (hasExistingCouponInState && !existingCouponInState?.isStripeCoupon) {
    return false;
  }

  const isRecurly = provider === providers.recurly;
  const hasActiveSub =
    existingSubscriptionStatus === premiumSubscriptionStatus.active;

  if (isRecurly && hasActiveSub) {
    return false;
  }

  return (
    provider === providers.stripe ||
    (selectExperiment(state, STRIPE_ENABLED, false) &&
      (!isRecurly || (isRecurly && !hasActiveSub)))
  );
}

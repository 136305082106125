import { asyncConnect } from 'redux-connect';

export default function connectAsync(dependencies, ...args) {
  const arrayOfPromises = dependencies.map((dependency) => ({
    promise: (componentProps) => {
      const { store } = componentProps;
      const maybeActionResponse = dependency(componentProps);

      return Promise.resolve(
        maybeActionResponse
          ? store.dispatch(maybeActionResponse)
          : maybeActionResponse,
      );
    },
  }));

  return asyncConnect(arrayOfPromises, ...args);
}

import { getQueryObjectFromLocation } from 'src/common/utils/queryString/parse';
import {
  PROFILE_QUERY_PARAM,
  SOURCE_QUERY_PARAM,
} from '../constants/queryParams';

/**
 *
 * @param location {object}
 * @param excludeProfileQueryParam {boolean}
 * @returns {string}
 * This function will check if the query param has a {resourceKey} prefix.
 * If it does, it will pull use text from localization if one exists.
 */
export default function generateOriginLabelFromQuery(
  location,
  excludeProfileQueryParam = false,
) {
  const query = getQueryObjectFromLocation(location);
  const labelArray = [query?.[SOURCE_QUERY_PARAM]];

  if (query?.[PROFILE_QUERY_PARAM] && !excludeProfileQueryParam) {
    labelArray.push(query[PROFILE_QUERY_PARAM]);
  }

  return labelArray.filter(Boolean).join('.');
}

import { fetchCategoryWithContextEffect as fetchNowPlayingContainerItemsAction } from '../../actions/browse';
import { FETCH_NOW_PLAYING_CATEGORY } from '../../actions/dialog';
import { fetchNowPlaying as fetchNowPlayingAction } from '../../actions/player';
import { NOW_PLAYING_GUIDE_ID } from '../../constants/dialogs/nowPlaying';
import getTunedGuideId from '../guideItem/getTunedGuideId';
import isDirectToNowPlaying from '../isDirectToNowPlaying';

export default function setUpDirectToNowPlaying(guideItemRouteContext) {
  const { audioClipId, guideId, topicGuideId } = guideItemRouteContext;

  return async (dispatch, getState) => {
    const state = getState();
    const { app, config, profiles } = state;
    const guideItem = profiles[guideId];

    if (isDirectToNowPlaying(app, config, guideId)) {
      const tunedGuideId = getTunedGuideId(
        guideItem,
        audioClipId,
        topicGuideId,
      );

      const fetchNowPlayingPromise = dispatch(
        fetchNowPlayingAction(tunedGuideId),
      );
      const fetchNowPlayingItemsPromise = dispatch(
        fetchNowPlayingContainerItemsAction(
          FETCH_NOW_PLAYING_CATEGORY,
          NOW_PLAYING_GUIDE_ID,
          tunedGuideId,
        ),
      );

      return await Promise.all([
        fetchNowPlayingPromise,
        fetchNowPlayingItemsPromise,
      ]);
    }

    return Promise.resolve();
  };
}

import url from 'url';
import qs from 'qs';

export default function addQuery(link, queryObj) {
  const linkObj = url.parse(link);
  const existingQuery = qs.parse(linkObj.query);

  return url.format({
    protocol: linkObj.protocol,
    host: linkObj.host,
    pathname: linkObj.pathname,
    query: {
      ...existingQuery,
      ...queryObj,
    },
  });
}

import get from 'lodash/get';
import { isRootCategory } from '../guideItemTypes';
import scrubSeoInfo from './scrubSeoInfo';

function getDestinationInfo(guideItem) {
  const behaviorAction = get(guideItem, 'behaviors.default.actionName');

  if (behaviorAction === 'Browse') {
    return guideItem.actions.browse.destinationInfo;
  } else if (behaviorAction === 'Profile') {
    return guideItem.actions.profile.destinationInfo;
  }

  return {};
}

export function getCanonicalTitle(guideItem) {
  const categoryTitle = get(guideItem, 'metadata.properties.seoInfo.title');

  // needed because of inconsistencies between how the seo title
  // is returned in category/profile API responses
  const profileTitle = get(guideItem, 'properties.seoInfo.title');
  return categoryTitle || profileTitle || null;
}

export function getUrlTitle(guideItem) {
  const destinationInfo = getDestinationInfo(guideItem);

  if (isRootCategory(destinationInfo.id)) {
    return destinationInfo.id;
  }

  const title = destinationInfo.seoName || getCanonicalTitle(guideItem);
  return scrubSeoInfo(title);
}

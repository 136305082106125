import { manualDiscordModeCookie } from '../constants/cookies';
import {
  DISCORD_GUILD_ID,
  DISCORD_INSTANCE_ID,
} from '../constants/queryParams';
import { getCookie } from './cookie';
import isServer from './isServer';

const isDiscord =
  !isServer() && document.documentElement.classList.contains('discord');
const isManualDiscordMode =
  !isServer() && !!getCookie(manualDiscordModeCookie.name);

export function isInDiscordIFrame() {
  return !isServer() && isDiscord && !isManualDiscordMode;
}

export function isDiscordMode() {
  return !isServer() && isDiscord;
}

export async function discordFriendlyExternalLinking(url) {
  if (isInDiscordIFrame()) {
    const { discordManager } = await import(
      '../components/discord/discordManager'
    );

    return discordManager.openExternalLink(url);
  }

  if (isManualDiscordMode) {
    window.open(url);
  }
}

export function getActivityIdQueryParam() {
  return new URLSearchParams(window.location.search).get(DISCORD_INSTANCE_ID);
}

export function getGuildIdQueryParam() {
  return new URLSearchParams(window.location.search).get(DISCORD_GUILD_ID);
}

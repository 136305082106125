import { MAC_DESKTOP_OS } from '../../constants/desktop';
import vars, {
  GA_PROPERTY_ID,
  GA_PROPERTY_ID_MAC_DESKTOP,
  GA_PROPERTY_ID_WINDOWS_DESKTOP,
} from '../../vars';

export function getGoogleAnalyticsProperty(desktopOS) {
  let propertyId = vars.get(GA_PROPERTY_ID);

  if (desktopOS) {
    propertyId = vars.get(
      desktopOS === MAC_DESKTOP_OS
        ? GA_PROPERTY_ID_MAC_DESKTOP
        : GA_PROPERTY_ID_WINDOWS_DESKTOP,
    );
  }

  return propertyId;
}

export function move(arr, fromIndex, toIndex) {
  const cloneArr = arr.slice();
  const element = cloneArr[fromIndex];
  cloneArr.splice(fromIndex, 1);
  cloneArr.splice(toIndex, 0, element);
  return cloneArr;
}

export function remove(arr, idx) {
  const cloneArr = arr.slice();
  cloneArr.splice(idx, 1);
  return cloneArr;
}

import get from 'lodash/get';
import { COUPON_QUERY_PARAM } from 'src/common/constants/queryParams';
import { COUPON_FLOW } from 'src/common/constants/subscription/flow';
import parse from '../queryString/parse';

/**
 * isCouponFlow - checks the location state if `couponflow=true` is present.
 *
 * @param  {type} location the location state descriptor
 * @return {type}          boolean, indicating if `couponflow=true` is present
 */
export default function isCouponFlow(location) {
  // Parse the query directly from the search string since
  // this utility may be used within top level route components
  // where the query object is not available on the client. See
  // this ticket, TUNE-10780, for details.
  const query = parse(get(location, 'search', ''));
  return get(query, COUPON_FLOW) === 'true' && !!get(query, COUPON_QUERY_PARAM);
}

import isArray from 'lodash/isArray';
import isLocalStorageAvailable from '../isLocalStorageAvailable';

const searchKey = 'recent.searches';

export function getSearches() {
  if (!isLocalStorageAvailable()) {
    return [];
  }
  const searches = window.localStorage.getItem(searchKey);
  if (!searches) {
    return [];
  }
  try {
    const parsed = JSON.parse(searches);
    return isArray(parsed) ? parsed : [];
  } catch (ex) {
    return [];
  }
}

export function setSearches(recentSearches) {
  if (!isLocalStorageAvailable()) {
    return;
  }
  try {
    window.localStorage.setItem(searchKey, JSON.stringify(recentSearches));
  } catch (ex) {
    // noop
  }
}

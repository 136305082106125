export default function appendScript(src, onLoad) {
  /* eslint-disable */
  !((j, o, r, g, e) => {
    g = j.createElement(o);
    g.onload = onLoad;
    g.async = true;
    g.src = r;
    g.type = 'text/javascript';
    e = j.getElementsByTagName(o)[0];
    e.parentNode.insertBefore(g, e);
  })(document, 'script', src);
  /* eslint-enable */
}

import get from 'lodash/get';
import { ENABLE_STATION_PROFILE_RECOMMENDED_CONTENT } from '../constants/experiments/config';
import { isStation } from './guideItemTypes';

export function shouldFetchRecommendedContent(guideId, state) {
  return (
    isStation(guideId) &&
    get(state, [
      'config',
      'autoupdate',
      ENABLE_STATION_PROFILE_RECOMMENDED_CONTENT,
    ])
  );
}

export function isProfileInactive(guideId, profiles) {
  const profile = profiles[guideId];

  if (profile) {
    return profile.isInactive;
  }

  return true;
}

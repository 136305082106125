import get from 'lodash/get';
import { MW_LANDING_DIRECT_TO_NOW_PLAYING } from '../constants/experiments/mobileLanding';
import { isProfile } from './guideItemTypes';
import isServer from './isServer';

export default function isDirectToNowPlaying(app, config, guideId) {
  const isMobile = get(app, 'isMobile');
  const mwLandingDirectToNowPlayingTest = get(config, [
    'autoupdate',
    MW_LANDING_DIRECT_TO_NOW_PLAYING,
  ]);

  return (
    isServer() &&
    mwLandingDirectToNowPlayingTest &&
    isMobile &&
    isProfile(guideId)
  );
}

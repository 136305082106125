import { selectIsAuthenticated } from '../selectors/auth';
import { getSelectChangedFavoritesForGuideId } from '../selectors/me';
import { getSelectGuideIdFollowOptions } from '../selectors/player';
import { getSelectProfileIsFollowing } from '../selectors/profiles';

/**
 * A util that returns the guideId follow state based on follow states that have changed
 * during the current session or falls back to the initial follow state of the guideItem
 * @param state - redux store state
 * @param guideId - a guide id of the guide item
 * @param [fallbackIsFollowing] - optional fallback following state to use
 * @returns {boolean}
 */
const isFollowingGuideId = (state, guideId, fallbackIsFollowing) => {
  if (!selectIsAuthenticated(state)) {
    return false;
  }

  // store.me.changedFavorites.(guideId)
  const controlledIsFollowing =
    getSelectChangedFavoritesForGuideId(guideId)(state);
  // store.player.followOptions.(guideId).isFollowing
  const { isFollowing: nowPlayingIsFollowing } =
    getSelectGuideIdFollowOptions(guideId)(state);
  // store.profiles.(guideId).actions.follow.isFollowing - this is initial data
  const profileIsFollowing = getSelectProfileIsFollowing(guideId)(state);

  return !!(
    controlledIsFollowing ??
    nowPlayingIsFollowing ??
    profileIsFollowing ??
    fallbackIsFollowing
  );
};

export default isFollowingGuideId;

import rootCategoriesData from 'src/data/rootCategories.json';
import { MISSING_GUIDE_ITEM_LINK_INFO } from '../../constants/errors';
import { types as GuideItemTypes, isRootCategory } from '../guideItemTypes';
import scrubSeoInfo from './scrubSeoInfo';

const { guideIdsToPathnames: rootCategories } = rootCategoriesData;

export default function determineGuideItemPathname(guideId, title, type) {
  if (isRootCategory(guideId)) {
    return rootCategories[guideId];
  }

  if (type === GuideItemTypes.user) {
    return encodeURIComponent(title);
  }

  // At this point, both guideId and title are required to properly form this portion of the url
  // path. While before a runtime invalid operation on null/undefined error would be thrown,
  // let's throw a specific Error here that we can latch on to further up the chain. This will allow
  // us to:
  // 1) log this particular error (on the client and server)
  // 2) specify a null value as the resultant path. It's up to the various functions that are using
  //    guide item links to properly handle null situations then (e.g. if for a link, we just render
  //    and anchor without an href).
  if (!guideId || !title) {
    const missingInfoError = Error(MISSING_GUIDE_ITEM_LINK_INFO);

    missingInfoError.context = {
      guideId,
      title,
    };

    throw missingInfoError;
  }

  const scrubbedTitle = scrubSeoInfo(title);
  return `${scrubbedTitle}-${guideId}`;
}

import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import trim from 'lodash/trim';
import isEqual from 'react-fast-compare';
import {
  BIRTH_YEAR_INVALID,
  BIRTH_YEAR_INVALID_FORMAT,
} from 'src/common/constants/localizations/auth';
import {
  FULL_NAME_TOO_LONG,
  INVALID_EMAIL,
  INVALID_PASSWORD,
  PASSWORDS_DONT_MATCH,
} from 'src/common/constants/localizations/shared';
import isEmail from 'validator/lib/isEmail';
import getCurrentYear from '../date/getCurrentYear';

const passwordRegex = /(?=^.{8,32}$)(?=.*[a-zA-Z])(?=.*[^a-zA-Z]).*$/;
const birthYearRegex = /^\d{4}$/;

export function isValueEmpty(value) {
  return isEmpty(trim(value));
}

export function concatFieldError(error, field, errors = {}) {
  const newErrors = errors;

  newErrors[field] = newErrors[field] || [];
  newErrors[field].push(error);

  return newErrors;
}

export function addToBaseError(error, field, errors = {}) {
  const newErrors = errors;

  newErrors._error = concatFieldError(error, field, newErrors._error);

  return newErrors;
}

export function addError(error, field, errors) {
  const newErrors = concatFieldError(error, field, errors);
  return addToBaseError(error, field, newErrors);
}

export function addRequiredFields(values, requiredFields, errors = {}) {
  let newErrors = errors;

  requiredFields.forEach((fieldDef) => {
    if (isValueEmpty(values[fieldDef])) {
      newErrors = addError('Required field', fieldDef, newErrors);
    }
  });

  return newErrors;
}

export function addRequiredFieldErrors(values, requiredFields, errors = {}) {
  let newErrors = errors;

  requiredFields.forEach((fieldDef) => {
    if (isObject(fieldDef)) {
      if (isValueEmpty(values[fieldDef.field])) {
        const error = fieldDef.error;

        newErrors = addError(error, fieldDef.field, newErrors);
      }
    } else {
      if (isValueEmpty(values[fieldDef])) {
        const error = `${startCase(fieldDef)} is required`;

        newErrors = addError(error, fieldDef, newErrors);
      }
    }
  });

  return newErrors;
}

export function addConditionalRequiredFieldErrors(
  values,
  requiredFields,
  errors = {},
) {
  let newErrors = errors;
  const { requiredField, conditionalValuesList, conditionalField } =
    requiredFields;

  if (
    indexOf(conditionalValuesList, values[requiredField]) !== -1 &&
    isValueEmpty(values[conditionalField])
  ) {
    const error = `${startCase(conditionalField)} is required`;

    newErrors = addError(error, conditionalField, newErrors);
  }

  return newErrors;
}

export function doesErrorExistForOtherFields(errors, errorText) {
  return reduce(
    errors,
    (doesExist, fieldErrors) => doesExist || includes(fieldErrors, errorText),
    false,
  );
}

export function addPasswordError(values, field, errors = {}) {
  let newErrors = errors;

  if (values[field] && !values[field].match(passwordRegex)) {
    if (doesErrorExistForOtherFields(errors._error, INVALID_PASSWORD)) {
      newErrors = concatFieldError(INVALID_PASSWORD, field, newErrors);
    } else {
      newErrors = addError(INVALID_PASSWORD, field, newErrors);
    }
  }

  return newErrors;
}

export function addEmailError(values, field, errors = {}) {
  let newErrors = errors;
  const email = trim(values[field]);

  if (email && !isEmail(email)) {
    newErrors = addError(INVALID_EMAIL, field, errors);
  }

  return newErrors;
}

export function addComparePasswordsError(values, field1, field2, errors = {}) {
  let newErrors = errors;
  const field1Val = values[field1];
  const field2Val = values[field2];

  if (field1Val && field2Val && !isEqual(values[field1], values[field2])) {
    newErrors = addError(PASSWORDS_DONT_MATCH, field2, errors);
  }

  return newErrors;
}

// Simple function map handler for creating error objects from api errors.
export function createApiValidationMapHandler(handlers) {
  return (values, faultKey, faultText, error = { _error: {} }) => {
    if (!handlers) return error;

    let newError = error;
    const handler = handlers[faultKey];

    if (handler) {
      newError = handler(values, error, faultText);
    }

    return newError;
  };
}

export function consolidateBaseError(baseErrorMsg, error = { _error: {} }) {
  const newError = error;

  if (isEmpty(error._error)) {
    newError._error = { base: baseErrorMsg };
  }

  return newError;
}

export function addFullNameLengthError(values, field, errors = {}) {
  let newErrors = errors;
  const name = values[field];

  if (name) {
    const firstPart = name.substr(0, name.indexOf(' '));
    const lastPart = name.substr(name.indexOf(' ') + 1);

    if (
      (firstPart && firstPart.length > 20) ||
      (lastPart && lastPart.length > 20)
    ) {
      newErrors = addError(FULL_NAME_TOO_LONG, field, newErrors);
    }
  }
  return newErrors;
}

export const requiredField = (value) => (value ? undefined : 'required field');

export const isRequired =
  (errorMessage) =>
  (value = '') =>
    value.trim?.() ? undefined : [errorMessage];

export const isValidPassword = (value) =>
  value && value.match(passwordRegex) ? undefined : [INVALID_PASSWORD];

export const isValidEmail = (value) => {
  const email = value?.trim?.();
  return email && isEmail(email) ? undefined : [INVALID_EMAIL];
};

export const isValidBirthYear = (value) => {
  const intYear = Number.parseInt(value, 10);
  const currentYear = getCurrentYear();
  if (value) {
    if (!birthYearRegex.test(value)) {
      return [BIRTH_YEAR_INVALID_FORMAT];
    } else if (intYear < currentYear - 100 || intYear >= currentYear) {
      return [BIRTH_YEAR_INVALID];
    }
  }
};

export const isValidFullName = (value) => {
  if (value) {
    const first = value.substr(0, value.indexOf(' '));
    const last = value.substr(value.indexOf(' ') + 1);
    if ((first && first.length > 20) || (last && last.length > 20)) {
      return [FULL_NAME_TOO_LONG];
    }
  }
};

export const isMatch = (fieldToMatch, msg) => (value, values) => {
  if (value !== values[fieldToMatch]) {
    return [msg];
  }
};

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce(
      (error, validator) => error || validator(value, values),
      undefined,
    );

export const baseError = (errorMessage, values) => ({
  base: errorMessage,
  _values: values,
});

export const validateForm = (validations) => (fieldObj) => {
  const obj = {};
  Object.keys(fieldObj).forEach((fieldName) => {
    const result = validations[fieldName]?.();
    if (result) {
      obj[fieldName] = result;
    }
  });
  return obj;
};

export const isLengthLessThanOrEqual = (length, msg) => (value) =>
  value?.length <= length ? undefined : [msg];

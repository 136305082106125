import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { sortByBillingPeriod } from 'src/common/utils/subscription/billingPeriod';
import {
  getDefaultRenewSkus,
  getDefaultSubscribeSkus,
  getPartnerSkus,
} from 'src/common/utils/subscription/getSkus';
import mapRecurlySkus from 'src/common/utils/subscription/mapZuoraSkus';
import {
  SELECTED_SKU_QUERY_PARAM,
  SKU_QUERY_PARAM,
} from '../../constants/queryParams';
import { getOauthPartnerId } from '../queryParams';
import { getQueryObjectFromLocation } from '../queryString/parse';

export function findProductBySku(products, sku) {
  return find(products, (p) => p.sku?.toLowerCase() === sku?.toLowerCase());
}

export function getSSFromQuery(location) {
  const query = getQueryObjectFromLocation(location);

  return query?.[SELECTED_SKU_QUERY_PARAM];
}

export function getSkusFromQuery(location) {
  const query = getQueryObjectFromLocation(location);
  let sku = query?.sku;
  sku = Array.isArray(sku) ? sku : sku?.split(',');
  const subscribeSkus = sku ? mapRecurlySkus(sku) : [];
  return uniq(subscribeSkus);
}

export function getDefaultSkus(location, configAutoUpdate, isRenew) {
  const queryObject = getQueryObjectFromLocation(location);
  const partnerId = getOauthPartnerId(queryObject);
  const defaultSkus = isRenew
    ? getDefaultRenewSkus(configAutoUpdate)
    : getDefaultSubscribeSkus(configAutoUpdate);
  return partnerId
    ? getPartnerSkus(configAutoUpdate, partnerId) || defaultSkus
    : defaultSkus;
}

/**
 * reconcileSKUQueryParam is a helper function which provides support for multiple sku queryParams (ss && sku)
 * it seems that the app uses both versions, this helper extends flexibility to help support different deeplink formats
 * @param location {object}
 * @return {string}
 *
 */
export function reconcileSKUQueryParam(location) {
  const skusFromQuery = getSkusFromQuery(location);

  return !isEmpty(skusFromQuery) ? skusFromQuery[0] : getSSFromQuery(location);
}

/**
 * returns the sku object of the first sku listed in the 'sku' query param
 * fallsback on 'ss' queryParam, in the case 'sku' is not found
 * @param products {object}
 * @param location {object}
 * @return {string}
 *
 */
export function selectProductFromQuery(products, location) {
  const sku = reconcileSKUQueryParam(location);

  return findProductBySku(products, sku);
}

/**
 * The counterpart to selectProductFromQuery,
 * selectProductsFromQuery is driven by the 'sku' queryParam
 * it returns an array of all available sku objects, that match the 'sku' queryParam (comma separated list)
 * @param products {object}
 * @param location {object}
 * @return {array}
 *
 */
export function selectProductsFromQuery(products, location) {
  const skusFromQuery = getSkusFromQuery(location);

  return skusFromQuery.reduce((acc, currentSku) => {
    const product = findProductBySku(products, currentSku);
    if (product?.sku) {
      acc.push(product);
    }
    return acc;
  }, []);
}

export function getSelectedSku(products, location) {
  const query = location?.query;
  const querySku =
    query?.[SELECTED_SKU_QUERY_PARAM] || query?.[SKU_QUERY_PARAM];
  const skusFromQuery = Array.isArray(querySku)
    ? querySku
    : querySku?.split(',');

  const sortedProductsFromQuery = skusFromQuery
    ?.map((sku) => products?.find((product) => product?.sku === sku))
    ?.sort(sortByBillingPeriod);

  return (
    sortedProductsFromQuery?.[0]?.sku ||
    products?.sort(sortByBillingPeriod)?.[0]?.sku
  );
}

import { isCategory } from '../guideItemTypes';

const homePath = 'home';

export default function getDeepLinkPath(guideId) {
  if (guideId) {
    if (isCategory(guideId)) {
      return `browse/${guideId}`;
    }

    return `profile/${guideId}`;
  }

  return homePath;
}

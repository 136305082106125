import get from 'lodash/get';
import { AUTH_OAUTHPREMIUMFLOW_ENABLED_PARTNERIDSKUS } from '../../constants/experiments/auth';
import {
  MONTHLY_SUBSCRIPTION,
  MONTHLY_SUBSCRIPTION_RENEW_SKU,
  YEARLY_SUBSCRIPTION,
  YEARLY_SUBSCRIPTION_RENEW_SKU,
} from '../../constants/experiments/skus';
import {
  selectDefaultRenewProductOverrides,
  selectDefaultSubscribeProductOverrides,
} from '../../selectors/config';
import vars from '../../vars';

export function getDefaultSubscribeSkus(configAutoUpdate) {
  const productOverrides = selectDefaultSubscribeProductOverrides({
    config: { autoupdate: configAutoUpdate },
  });

  if (Array.isArray(productOverrides) && productOverrides.length) {
    return productOverrides;
  }

  const yearlySku = get(
    configAutoUpdate,
    YEARLY_SUBSCRIPTION,
    vars.get('YEARLY_SKU'),
  );
  const monthlySku = get(
    configAutoUpdate,
    MONTHLY_SUBSCRIPTION,
    vars.get('MONTHLY_SKU'),
  );

  // keep order of skus as annual, monthly. See PaymentForm.
  return [yearlySku, monthlySku];
}

export function getDefaultRenewSkus(config) {
  const productOverrides = selectDefaultRenewProductOverrides({ config });

  if (Array.isArray(productOverrides) && productOverrides.length) {
    return productOverrides;
  }

  const yearlyRenewSku = get(
    config,
    YEARLY_SUBSCRIPTION_RENEW_SKU,
    vars.get('YEARLY_RENEW_SKU'),
  );
  const monthlyRenewSku = get(
    config,
    MONTHLY_SUBSCRIPTION_RENEW_SKU,
    vars.get('MONTHLY_RENEW_SKU'),
  );

  // keep order of skus as annual, monthly. See AccountSettingsConnector.
  return [yearlyRenewSku, monthlyRenewSku];
}

export function getPartnerSkus(config, partnerId) {
  const configPartnerSkus = get(
    config,
    AUTH_OAUTHPREMIUMFLOW_ENABLED_PARTNERIDSKUS,
  );

  return (configPartnerSkus && configPartnerSkus[partnerId]) || null;
}

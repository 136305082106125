import qs from 'qs';

import {
  BROWSE_QUERY_PARAM,
  PROFILE_QUERY_PARAM,
} from '../../constants/queryParams';
import { isCategory, isProfile } from '../guideItemTypes';
import { excludeUtmParams } from '../utmCampaignParams';

export default function createSubscribeLink({
  source,
  guideId,
  location,
  additionalParams,
}) {
  const queryParams = {
    ...excludeUtmParams(location.query),
    ...additionalParams,
    vt: 'su',
    source,
    [BROWSE_QUERY_PARAM]: isCategory(guideId) ? guideId : undefined, // will ignore if undefined
    [PROFILE_QUERY_PARAM]: isProfile(guideId) ? guideId : undefined,
  };
  return {
    pathname: '/subscribe/',
    query: queryParams,
    search: qs.stringify(queryParams),
  };
}

import isEqual from 'react-fast-compare';
import { mintSingleton } from 'src/client/mint';
import eula, {
  viewTypeToConsentCategoryMap,
} from '../../constants/analytics/categoryActionLabel/eula';
import {
  selectCurrentEulaType,
  selectCurrentEulaVersion,
} from '../../selectors/config';
import { selectAuthViewType } from '../../selectors/loginDetails';
import { selectUserAccountId } from '../../selectors/me';
import vars from '../../vars';
import {
  brazeAppVersion,
  brazeAppVersionNumber,
  brazeContainerElementId,
} from './constants';
import { getMessageConfig, transformCards } from './utils';

const BRAZE_CARD_TIMEOUT = 5000;
const {
  promise: contentCardsReadyPromise,
  resolve: contentCardsReadyResolver,
} = Promise.withResolvers();

export async function initBraze(config) {
  const {
    actions,
    stateRef,
    isDiscord,
    isBrazeContentCardsExperimentEnabled,
    brazeSessionTimeoutInSeconds,
  } = config;

  try {
    const braze = await import(
      /* webpackExports: ["changeUser", "getUser", "initialize", "setCustomUserAttribute", "openSession", "subscribeToContentCardsUpdates", "requestContentCardsRefresh"] */
      '@braze/web-sdk'
    );

    const shouldEnableLogging =
      new URLSearchParams(window.location.search).get('debugBraze') === 'true';

    braze.initialize(
      vars.get(isDiscord ? 'DISCORD_BRAZE_API_KEY' : 'BRAZE_API_KEY'),
      {
        baseUrl: vars.get('BRAZE_SDK_URL'),
        enableLogging: shouldEnableLogging,
        allowUserSuppliedJavascript: true,
        appVersion: brazeAppVersion,
        appVersionNumber: brazeAppVersionNumber,
        sessionTimeoutInSeconds: brazeSessionTimeoutInSeconds,
      },
    );

    const { isAuthenticated, userAccountId, userLocation } = stateRef.current;

    if (isAuthenticated && userAccountId) {
      braze.changeUser(userAccountId);
    }

    const user = await braze.getUser();

    brazeSetUserLocation(user, userLocation);
    user.setCustomUserAttribute('isRegistered', isAuthenticated);

    if (isBrazeContentCardsExperimentEnabled) {
      // Triggers the Content Cards to refresh automatically.
      braze.subscribeToContentCardsUpdates((cards) => {
        contentCardsReadyResolver();
        actions.logReceivedContentCardsCount(cards?.cards?.length);
      });

      // If Braze Content Cards haven't loaded within the timeout, manually request a refresh.
      setTimeout(async () => {
        const isPending = await Promise.race([contentCardsReadyPromise, true]);

        if (isPending) {
          braze.requestContentCardsRefresh();
        }
      }, BRAZE_CARD_TIMEOUT);
    } else {
      contentCardsReadyResolver();
    }

    if (!isDiscord) {
      await handleBrazeInAppMessages({ actions, stateRef });
    }

    braze.openSession();
    actions.setIsBrazeInitialized();
  } catch (err) {
    actions.logClientError({
      message: 'Braze Init Error',
      context: {
        error: err,
      },
    });
    // NOTE: Don't break app functionality for Braze
  }
}

/**
 * Docs: https://tunein.atlassian.net/wiki/x/CwD7ng
 *
 * @param consentVersion
 * @param consentType
 * @returns {Promise<void>}
 */
export async function brazeLogAgreementConsent(consentVersion, consentType) {
  if (!consentVersion || !consentType) return;

  try {
    const braze = await import(
      /* webpackExports: ["getUser"] */
      '@braze/web-sdk'
    );
    const user = await braze.getUser();

    user.setCustomUserAttribute(
      `${consentType}AgreementConsent`,
      consentVersion,
    );
  } catch (e) {
    // noop
  }
}

export async function handleBrazeInAppMessages({ actions, stateRef }) {
  try {
    const braze = await import(
      /* webpackExports: ["subscribeToInAppMessage", "showInAppMessage"] */
      '@braze/web-sdk'
    );

    const makeOnShowInAppMessage =
      (messageHandler, extras, closeMessage) => () => {
        const brazeDocument = document.querySelector(
          `#${brazeContainerElementId} iframe`,
        )?.contentWindow?.document;

        messageHandler?.({
          extras,
          actions,
          stateRef,
          brazeDocument,
          callbacks: {
            brazeLogAgreementConsent,
            closeMessage,
          },
        });
      };

    braze.subscribeToInAppMessage(async (inAppMessage) => {
      const { isBrazeFriendlyPage } = stateRef.current || {};
      const isAudioOrVideoAdPlaying = mintSingleton.instance?.getState(
        'isAudioOrVideoAdPlaying',
      );

      // NOTE: Braze In App Messages are Modal Screen Takeovers, and so are Video Ads.
      // To ensure that Braze In App Messages are not rendered while a Video ad is playing, the app(tune action)
      // makes sure the Braze custom event to trigger In App Message is sent right after the preroll has finished.
      if (!isBrazeFriendlyPage || isAudioOrVideoAdPlaying) {
        return;
      }

      const { extras } = inAppMessage || {};
      const { messageHandler, canShowMessage } = getMessageConfig(extras);

      // Only some in-app messages require handlers or have conditional requirements
      if (!messageHandler && !canShowMessage) {
        return braze.showInAppMessage(inAppMessage);
      }

      // Only some in-app messages have conditional requirements
      if (canShowMessage && !canShowMessage({ stateRef })) {
        return;
      }

      const brazeContainer = document.createElement('div');

      brazeContainer.setAttribute('id', brazeContainerElementId);
      document.querySelector(`#${brazeContainerElementId}`)?.remove();
      document.body.appendChild(brazeContainer);
      braze.showInAppMessage(
        inAppMessage,
        brazeContainer,
        makeOnShowInAppMessage(messageHandler, extras, () =>
          inAppMessage.closeMessage(),
        ),
      );
    });
  } catch (e) {
    // noop
  }
}

export async function brazeOnAuth(
  isAuthenticated,
  userLocation,
  actions,
  reduxStore,
) {
  try {
    const braze = await import(
      /* webpackExports: ["getUser", "changeUser"] */
      '@braze/web-sdk'
    );

    if (!isAuthenticated) {
      const user = await braze.getUser();
      user.setCustomUserAttribute('isRegistered', isAuthenticated);
      return;
    }

    const state = reduxStore.getState();
    const userAccountId = selectUserAccountId(state);
    const authViewType = selectAuthViewType(state);
    const currentEulaVersion = selectCurrentEulaVersion(state);
    const currentEulaType = selectCurrentEulaType(state);
    const { name, action } =
      eula.subcategory[viewTypeToConsentCategoryMap[authViewType]] || {};

    braze.changeUser(userAccountId);

    const user = await braze.getUser();

    brazeSetUserLocation(user, userLocation);
    user.setCustomUserAttribute('isRegistered', isAuthenticated);

    if (name && action) {
      brazeLogAgreementConsent(currentEulaVersion, currentEulaType);

      actions.logEULAActivity(name, action.accept.name, {
        eulaVersion: currentEulaVersion,
        eulaType: currentEulaType,
      });
    }
  } catch (e) {
    // noop
  }
}

export async function brazeLogCustomEvent(customEvent, data) {
  try {
    const braze = await import(
      /* webpackExports: ["logCustomEvent"] */
      '@braze/web-sdk'
    );

    braze.logCustomEvent(customEvent, data);
  } catch (e) {
    // noop
  }
}

export async function brazeRequestContentCardsRefresh(
  shouldDelay,
  refreshDelaySeconds,
) {
  try {
    const braze = await import(
      /* webpackExports: ["requestContentCardsRefresh"] */
      '@braze/web-sdk'
    );

    if (!shouldDelay) {
      return braze.requestContentCardsRefresh();
    }

    // After a few seconds(configured by RM key) the latest cards for the new user status will be set to the cache, so the next navigation to
    // any Browse page will apply the latest cards from the cache.
    setTimeout(() => {
      braze.requestContentCardsRefresh();
    }, refreshDelaySeconds * 1000);
  } catch (e) {
    // noop
  }
}

function isDuplicateContentCard(card, uniqueCards) {
  return uniqueCards.some(
    (uniqueCard) =>
      uniqueCard.id === card.id && isEqual(uniqueCard.extras, card.extras),
  );
}

export async function getBrazeContentCards(
  guideId,
  isUserSubscribed,
  updateCardValidationErrors,
  logReceivedDuplicatedCards,
  currentContainerItemsCount,
  categorySeoInfo,
  isBrazeContentCardsRemoveDuplicatesEnabled,
) {
  const braze = await import(
    /* webpackExports: ["getCachedContentCards"] */
    '@braze/web-sdk'
  );

  await contentCardsReadyPromise;

  const { cards: cachedCards } = await braze.getCachedContentCards();
  const uniqueCards = [];
  const duplicatedCardIds = [];
  const validGuideIds = [guideId, categorySeoInfo?.guideId].filter(Boolean);
  const cards = cachedCards.filter((card) => {
    // Note: This is a temporal fix for the scenario when Braze returns the duplicate cards with same 'id' and 'extras' prop values. There's an ongoing conversation with Braze on how to fix the duplication issue, once we agreed on the solution we'll remove this condition.
    if (isBrazeContentCardsRemoveDuplicatesEnabled) {
      if (isDuplicateContentCard(card, uniqueCards)) {
        duplicatedCardIds.push(card.id);
        return false;
      }
      uniqueCards.push(card);
    }

    // Ignore the cards with screen_id different than the current page guideId.
    if (
      card.extras?.screen_id &&
      !validGuideIds.includes(card?.extras?.screen_id)
    ) {
      return false;
    }

    // Note: Braze may return not updated cards for the premium and non-premium user, so we additionally filter.
    if (card.extras?.premium_only === 'true') {
      return isUserSubscribed;
    }

    if (card.extras?.premium_only === 'false') {
      return !isUserSubscribed;
    }

    return true;
  });

  // Note: This is a temporal reporting, so we can understand how often Braze returns the duplicated cards to us. Once the duplication issue is fixed we'll remove this reporting.
  if (isBrazeContentCardsRemoveDuplicatesEnabled && duplicatedCardIds.length) {
    logReceivedDuplicatedCards(duplicatedCardIds);
  }

  if (cards?.length) {
    const { transformedCards, validationErrors } = transformCards(
      cards,
      currentContainerItemsCount,
      categorySeoInfo,
    );

    if (validationErrors.length) {
      updateCardValidationErrors(validationErrors);
    }

    return transformedCards;
  }
}

export async function brazeLogContentCardImpressions(cardsIds) {
  try {
    const braze = await import(
      /* webpackExports: ["getCachedContentCards", "logContentCardImpressions"] */
      '@braze/web-sdk'
    );

    const { cards: cachedCards } = await braze.getCachedContentCards();
    const cardsViewed = cachedCards.filter((card) =>
      cardsIds.includes(card?.id),
    );

    if (cardsViewed.length) {
      braze.logContentCardImpressions(cardsViewed);
    }
  } catch (e) {
    // noop
  }
}

export async function brazeLogContentCardClick(guideItem, actions) {
  try {
    const braze = await import(
      /* webpackExports: ["getCachedContentCards", "logContentCardClick"] */
      '@braze/web-sdk'
    );

    const { cards: cachedCards } = await braze.getCachedContentCards();
    const cardClicked = cachedCards.find(
      (card) => card?.id === guideItem.cardId,
    );

    if (cardClicked) {
      braze.logContentCardClick(cardClicked);
    }

    actions.logContentCardClick(guideItem);
  } catch (e) {
    // noop
  }
}

export async function brazeSetUserLocation(user, userLocation) {
  try {
    const { country_code: countryCode, state, city } = userLocation;

    if (countryCode) {
      user.setCustomUserAttribute('last_known_country_code', countryCode);
    }

    // Resetting state when not provided, so that Marketing can properly target/filter in Braze.
    user.setCustomUserAttribute('last_known_state', state || null);

    if (city) {
      user.setCustomUserAttribute('last_known_city', city);
    }
  } catch (e) {
    // noop
  }
}

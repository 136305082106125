import mapKeys from 'lodash/mapKeys';
import toLower from 'lodash/toLower';
import qs from 'qs';

export function parseObject(queryStringObject) {
  return mapKeys(queryStringObject, (v, k) => toLower(k));
}

export default function parse(queryString) {
  if (!queryString) {
    return {};
  }

  if (queryString[0] === '?') {
    return parseObject(qs.parse(queryString.slice(1)));
  }

  return parseObject(qs.parse(queryString));
}

export function getQueryObjectFromLocation(location = {}) {
  const { query, search } = location;
  return typeof query === 'object' ? query : parse(query || search);
}

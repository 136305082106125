import isLocalStorageAvailable from './isLocalStorageAvailable';

const key = 'player.volume';

export function setVolume(value) {
  if (isLocalStorageAvailable()) {
    window.localStorage.setItem(key, value);
  }
}

export function getVolume() {
  if (isLocalStorageAvailable()) {
    return Number.parseInt(window.localStorage.getItem(key), 10);
  }

  return null;
}

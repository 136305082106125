const billingFrequency = {
  daily: 'Day',
  monthly: 'Month',
  quarterly: 'Quarter',
  yearly: 'Annual',
};

export const stripeBillingPeriods = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
};

const billingFrequencySortOrder = Object.fromEntries(
  Object.values(billingFrequency).map((v, i) => [v, i]),
);

export function sortByBillingPeriod(a, b) {
  return billingFrequencySortOrder[a?.billingPeriod] <
    billingFrequencySortOrder[b?.billingPeriod]
    ? 1
    : -1;
}

export default billingFrequency;

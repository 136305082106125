import getRootCategorySlug from './guideItem/getRootCategorySlug';
import vanityPathsToGuideIds from './guideItem/vanityPathsToGuideIds';

// guideIdentifier is a parameter passed to routes as /radio/:guideIdentifier
// generally it's title-something-s123, but should also apply to root urls
// that have no hyphen, in which case the whole thing is guideId (such as music)
// I renamed this to be clearer
export default function parseGuideIdentifier(guideIdentifier) {
  const radioUrlParams = {};
  const lastHyphen = guideIdentifier.lastIndexOf('-');

  if (lastHyphen !== -1) {
    radioUrlParams.title = guideIdentifier.slice(0, lastHyphen);
    radioUrlParams.guideId = guideIdentifier.slice(
      lastHyphen + 1,
      guideIdentifier.length,
    );
  } else {
    radioUrlParams.title = guideIdentifier;
    radioUrlParams.guideId = guideIdentifier;
  }

  return radioUrlParams;
}

export function getGuideIdFromGuideIdentifier(guideIdentifier) {
  if (!guideIdentifier) {
    return undefined;
  }

  const lastHyphen = guideIdentifier.lastIndexOf('-');
  return lastHyphen !== -1
    ? guideIdentifier.slice(lastHyphen + 1, guideIdentifier.length)
    : guideIdentifier;
}

export function getGuideIdFromRoutePath(path) {
  if (!path || path === '/') {
    return undefined;
  }

  const guideIdOrVanitySlug = path.split('/').filter(Boolean).pop();
  const vanityGuideId = vanityPathsToGuideIds[guideIdOrVanitySlug];
  const rootCategoryId = getRootCategorySlug(guideIdOrVanitySlug);
  const guideId = guideIdOrVanitySlug.split('-').filter(Boolean).pop();
  return vanityGuideId || rootCategoryId || guideId;
}

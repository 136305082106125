import account from '../../constants/analytics/categoryActionLabel/account';

function filterAndJoinArray(array, delimiter = '') {
  return array.filter(Boolean).join(delimiter);
}

export function createEventString(event) {
  return [event.category, event.action, event.label, event.value]
    .join('|')
    .replace(/\|+$/, ''); // removes any trailing `|`
}

/**
 * Reusable function to concatenate labels with a `.`
 * @param {...string} args
 * @returns {string}
 */
export function appendToLabel(...args) {
  return filterAndJoinArray(args, '.');
}

/**
 * Reusable function to append partnerId to a string format commonly used for analytics logging
 * @param baseLabel {string}
 * @param partnerId {string}
 * @returns {string}
 */
export function appendPartnerIdToLabel(baseLabel, partnerId) {
  if (!partnerId) {
    return baseLabel;
  }

  return appendToLabel(baseLabel, account.labels.partner, partnerId);
}

/**
 * Prevents converting circular structure to JSON error
 */
const replacerFunc = () => {
  const visited = new WeakSet();

  return (key, value) => {
    if (typeof value !== 'object' || value === null) {
      return value;
    }

    if (visited.has(value)) {
      return;
    }

    visited.add(value);

    return value;
  };
};

export function safeJsonStringify(data, space) {
  try {
    return JSON.stringify(data, replacerFunc(), space);
  } catch (e) {
    return data;
  }
}

import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { getSearches as get, setSearches as set } from './utils';

const RECENT_SEARCHES_LIMIT = 10;

function add(term) {
  if (!isString(term)) {
    return get();
  }
  const storedSearches = get();
  const newSearchTerm = term.trim().toLowerCase();
  const searches = storedSearches.filter(
    (storedTerm) => storedTerm !== newSearchTerm,
  );
  if (searches.length >= RECENT_SEARCHES_LIMIT) {
    searches.pop();
  }
  searches.unshift(newSearchTerm);
  set(searches);
  return get();
}

function remove(index) {
  if (!isNumber(index)) {
    return get();
  }
  const storedSearches = get();
  storedSearches.splice(index, 1);
  set(storedSearches);
  return get();
}

function clear() {
  set([]);
  return get();
}

export default { get, add, remove, clear };

import {
  CLIENT_ID,
  PAIR_AUTH_FLOW,
  PARTNER_ID,
  SOURCE_QUERY_PARAM,
  USER_CODE,
} from '../constants/queryParams';

export function getOauthPartnerId(query) {
  return query?.[CLIENT_ID] || query?.[PARTNER_ID];
}

export function getPairAuthFlowParam(query) {
  return query?.[PAIR_AUTH_FLOW];
}

export function getUserCodeParam(query) {
  return query?.[USER_CODE];
}

export function getSourceParam(query) {
  return query?.[SOURCE_QUERY_PARAM];
}

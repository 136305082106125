// TODO: when all root navs should be treated as prefixes, just use `guideIdsToPathnames` from the
// json file, and then kill `prefixRedirects`
import rootCategoriesData from '../../../data/rootCategories.json';

const { prefixRedirects, pathsToGuideIds } = rootCategoriesData;

export default function getRootCategorySlug(guideId) {
  return prefixRedirects[guideId];
}

// move to own file
export function getRootCategoryGuideId(slug) {
  return pathsToGuideIds[slug];
}

import {
  selectSubscriptionProviderName,
  selectSubscriptionStatus,
} from '../../selectors/me';
import { selectCurrentSubStatus } from '../../selectors/subscription';
import { providers } from './getSubscriptionProvider';
import shouldUseStripe from './shouldUseStripe';
import subscribedThroughWeb from './subscribedThroughWeb';
import {
  currentSubscriptionStatus,
  premiumSubscriptionStatus,
} from './subscriptionStatus';

export default function getIsRenewalEligible(state) {
  const providerName = selectSubscriptionProviderName(state);
  const hasPreviouslySubscribedOnWeb = subscribedThroughWeb(providerName);

  if (!hasPreviouslySubscribedOnWeb) {
    return false;
  }

  const isStripeEnabled = shouldUseStripe(state);
  const isRecurly = providerName === providers.recurly;
  const premiumStatus = selectSubscriptionStatus(state);
  const providerSubscriptionStatus = selectCurrentSubStatus(state);
  const isProviderSubStatusExpired =
    providerSubscriptionStatus === currentSubscriptionStatus.expired;
  const isProviderSubStatusCanceled =
    providerSubscriptionStatus === currentSubscriptionStatus.canceled;
  const isPlatformSubStatusExpired =
    premiumStatus === premiumSubscriptionStatus.expired;

  if (isPlatformSubStatusExpired && isRecurly && isStripeEnabled) {
    return false;
  }

  return (
    isProviderSubStatusCanceled ||
    isProviderSubStatusExpired ||
    isPlatformSubStatusExpired
  );
}

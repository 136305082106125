import get from 'lodash/get';
import subscribedThroughWeb from './subscribedThroughWeb';
import { premiumSubscriptionStatus } from './subscriptionStatus';

export default function hasSubscribed(meDetails) {
  const meStatus = get(meDetails, 'subscriptionStatus');
  const meProvider = get(meDetails, 'subscriptionProviderName');

  const { active, expired } = premiumSubscriptionStatus;

  if (!meStatus) {
    return false;
  }

  // active subscription (any provider)
  if ([active].includes(meStatus)) {
    return true;
  }

  // expired web subscription
  if ([expired].includes(meStatus) && subscribedThroughWeb(meProvider)) {
    return true;
  }

  return false;
}

import { isType } from '@tunein/web-common';
import { fetchAudioClipProfile } from 'src/common/actions/audioClips';
import { types as GuideItemTypes } from '../guideItemTypes';

// set up with:
// createRadioProfileContext in ProfileConnector
// createVanityProfileContext in VanityProfileConnector
// getRadioContext in RadioRouteConnector
export default function setupFetchAudioClip(routeContextCreator) {
  return function fetchAudioClip(props) {
    const guideItemRouteContext = routeContextCreator(props);
    if (!guideItemRouteContext) {
      return null;
    }

    const { guideId, audioClipId } = guideItemRouteContext;
    if (
      isType(guideId, GuideItemTypes.station, GuideItemTypes.program) &&
      audioClipId
    ) {
      return fetchAudioClipProfile(audioClipId, guideId);
    }
    return null;
  };
}

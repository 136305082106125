import config from '../../config';
import accountCategory from '../../constants/analytics/categoryActionLabel/account';
import { fbStatus } from '../../constants/auth/oauth';
import { desktopFuncNames } from '../../constants/desktop';
import vars from '../../vars';
import invokeDesktopFunction from '../desktop/invokeDesktopFunction';
import isDesktop from '../desktop/isDesktop';
import appendScript from '../dom/appendScript';

const fbConfig = config.oauth.fb;

function _onScriptLoad(cb) {
  try {
    window.FB.init({
      version: `v${fbConfig.version}`,
      appId: vars.get('FB_CID'),
      xfbml: false,
      cookie: false,
    });
    cb();
  } catch (e) {
    cb(true);
  }
}

export function setupFBAuth(cb) {
  if (window.FB) {
    return cb();
  }

  // https://developers.facebook.com/docs/javascript/quickstart
  window.fbAsyncInit = () => _onScriptLoad(cb);
  appendScript(fbConfig.sdk);
}

export function handleDesktopFacebookAuth(token, actionHandler) {
  // after successful authorization via the desktop app, this
  // is triggered to manually login the user
  window.FB.api('/me', { fields: 'id', access_token: token }, (response) => {
    actionHandler(accountCategory.labels.facebook, {
      userId: response.id,
      token,
    });
  });
}

/* doFBSignIn:
 *   getLoginStatus
 *     a. if logged in, handle with actionhandler
 *     b. if not logged in, invoke unauthenticated callback
 *   triggerDesktopOrWebPermissionsDialog (passed as unauthenticated callback)
 *     a. if desktop, pass functionality to desktop code
 *     b. if web, invoke FB.login with authResponseHandler to invoke actionHandler
 *        no unauthenticated callback is needed here
 */
export function doFBSignIn(actionHandler, source, partnerParams) {
  function handleAuthenticatedUser(authResponse) {
    actionHandler(
      accountCategory.labels.facebook,
      { userId: authResponse.userID, token: authResponse.accessToken },
      source,
      partnerParams,
    );
  }

  function authResponseHandler(handleUnauthenticatedUser) {
    return (response) => {
      if (response.status === fbStatus.connected) {
        handleAuthenticatedUser(response.authResponse);
      } else if (handleUnauthenticatedUser) {
        handleUnauthenticatedUser();
      }
    };
  }

  function triggerDesktopOrWebPermissionsDialog() {
    if (isDesktop() && window.Bridge) {
      // set handler for when fbAuth is complete
      window.Bridge.handleFacebookDesktopAuthentication = (accessToken) => {
        handleDesktopFacebookAuth(accessToken, actionHandler);
      };
      // if the user hasn't authorized FB, the desktop flow
      // needs to manually open the dialog so it can respond
      // to the success/fail correctly
      invokeDesktopFunction(desktopFuncNames.openfacebookAuth, {
        clientId: vars.get('FB_CID'),
        ...fbConfig,
      });
    } else {
      // open login dialog via facebook sdk for web browser
      window.FB.login(authResponseHandler(), { scope: fbConfig.scope });
    }
  }

  window.FB.getLoginStatus(
    authResponseHandler(triggerDesktopOrWebPermissionsDialog),
  );
}

export function doFBSignOut() {
  // We force FB to check loginStatus using the 'true' flag
  // This will prevent caching and update the status.
  // Then once the status is updated, if its connected then we want to logout of facebook
  const logout = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === fbStatus.connected) {
        window.FB.logout();
      }
    }, true);
  };

  if (!window.FB) {
    setupFBAuth(logout);
  } else {
    logout();
  }
}

import { mapKeys, parseBoolean, parseFloat } from '@tunein/web-utils';
import jsCookie from 'js-cookie';
import * as cookieModule from '../constants/cookies';
import { permanentCookieExpirationDate } from '../constants/cookies';
import { isLocalEnv } from './environment';

const cookieMap = mapKeys(cookieModule, (cookie) => cookie.name);
const cookies = Object.values(cookieMap);

export function getCookie(cookieName) {
  const stringVal = jsCookie.get(cookieName);
  const possibleBool = parseBoolean(stringVal);

  if (typeof possibleBool === 'boolean') {
    return possibleBool;
  }

  return parseFloat(stringVal);
}

export function canSaveCookie(cookieName) {
  const activeCookieConsentCategories = getCookie(
    cookieModule.activeConsentCategoriesCookie.name,
  );

  const cookie =
    cookieMap[cookieName] ||
    cookies.some(({ name }) => cookieName?.startsWith(name));

  if (!cookie && isLocalEnv()) {
    throw new Error(`Attempted to set unknown cookie: ${cookieName}`);
  }

  return !!(
    !activeCookieConsentCategories ||
    (cookie && activeCookieConsentCategories.includes(cookie.consentCategory))
  );
}

export function setCookie(cookieName, daysOrDateComponents, value = true) {
  const isValidDaysOrDate =
    (daysOrDateComponents && typeof daysOrDateComponents === 'number') ||
    daysOrDateComponents instanceof Date;
  if (
    !canSaveCookie(cookieName) ||
    (!!daysOrDateComponents && !isValidDaysOrDate)
  ) {
    return;
  }

  jsCookie.set(cookieName, value, {
    path: '/',
    expires: daysOrDateComponents || permanentCookieExpirationDate, // expires is a # of days or Date value
  });
}

export function removeCookie(cookieName) {
  jsCookie.remove(cookieName, { path: '/' });
}

export function removeInactiveConsentCategoryCookies() {
  cookies.forEach((cookie) => {
    if (!canSaveCookie(cookie.name)) {
      removeCookie(cookie.name);
    }
  });
}

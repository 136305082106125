import { isTopic } from '@tunein/web-common';
import get from 'lodash/get';
import {
  ADDED_TO_FAVORITES,
  ADD_TO_FAVORITES_ERROR,
  EPISODE_ADDED_TO_FAVORITES,
  EPISODE_REMOVED_FROM_FAVORITES,
  PODCAST_ADDED_TO_FAVORITES,
  PODCAST_REMOVED_FROM_FAVORITES,
  REMOVED_FROM_FAVORITES,
  REMOVE_FROM_FAVORITES_ERROR,
  STATION_ADDED_TO_FAVORITES,
  STATION_REMOVED_FROM_FAVORITES,
} from '../constants/localizations/favorites';
import { isPodcastProfile, isStation } from './guideItemTypes';

const favoriteTextMap = {
  station: {
    favorited: STATION_ADDED_TO_FAVORITES,
    favoriteError: ADD_TO_FAVORITES_ERROR,
    unFavorited: STATION_REMOVED_FROM_FAVORITES,
    unFavoriteError: REMOVE_FROM_FAVORITES_ERROR,
  },
  podcast: {
    favorited: PODCAST_ADDED_TO_FAVORITES,
    favoriteError: ADD_TO_FAVORITES_ERROR,
    unFavorited: PODCAST_REMOVED_FROM_FAVORITES,
    unFavoriteError: REMOVE_FROM_FAVORITES_ERROR,
  },
  topic: {
    favorited: EPISODE_ADDED_TO_FAVORITES,
    favoriteError: ADD_TO_FAVORITES_ERROR,
    unFavorited: EPISODE_REMOVED_FROM_FAVORITES,
    unFavoriteError: REMOVE_FROM_FAVORITES_ERROR,
  },
  default: {
    favorited: ADDED_TO_FAVORITES,
    favoriteError: ADD_TO_FAVORITES_ERROR,
    unFavorited: REMOVED_FROM_FAVORITES,
    unFavoriteError: REMOVE_FROM_FAVORITES_ERROR,
  },
};

export const getFavoriteMessageText = (guideId = '', state = {}) => {
  const isPodcast = isPodcastProfile(get(state.profiles, guideId, false));

  if (isTopic(guideId)) {
    return favoriteTextMap.topic;
  }
  if (isPodcast) {
    return favoriteTextMap.podcast;
  }
  if (isStation(guideId)) {
    return favoriteTextMap.station;
  }
  return favoriteTextMap.default;
};

import { isHexColor } from 'validator';

// TODO: Candidate for utils module
function convertToSixChar(hex) {
  if (hex[0] === '#') {
    // eslint-disable-next-line no-param-reassign
    hex = hex.slice(1);
  }

  if (hex.length < 6) {
    return hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  return hex;
}

function getColor(hex, luminosity) {
  const lum = luminosity || 0;
  let newColor = '#';

  // convert to decimal and change luminosity
  // source: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
  for (let i = 0; i < 3; i++) {
    let c = Number.parseInt(hex.slice(i * 2).slice(0, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    newColor += `00${c}`.slice(c.length); // eslint-disable-line prefer-template
  }

  return newColor;
}

export function lightnessModifier(hex = '', luminosity) {
  // luminosity is a decimal value between 0 and 1 for lighter and -1 and 0 for darker
  if (!isHexColor(hex)) {
    return '';
  }

  const newHex = convertToSixChar(hex);
  return getColor(newHex, luminosity);
}

export function opacityModifier(hexColor = '', num) {
  if (!isHexColor(hexColor)) {
    return '';
  }

  const float = Number.parseFloat(num);

  // must be value between 0 and 1, inclusive
  if (Number.isNaN(float) || float < 0 || float > 1) {
    return '';
  }

  const hexOpacity = Math.round(255 * float).toString(16);
  return hexColor + hexOpacity;
}

export function validateAndGetHexColor(hex, fallback) {
  return hex && isHexColor(hex) ? hex : fallback;
}

// docs: https://developers.google.com/identity/sign-in/web/reference
import config from '../../config';
import accountCategory from '../../constants/analytics/categoryActionLabel/account';
import { desktopFuncNames } from '../../constants/desktop';
import vars from '../../vars';
import invokeDesktopFunction from '../desktop/invokeDesktopFunction';
import isDesktop from '../desktop/isDesktop';
import appendScript from '../dom/appendScript';
import isServer from '../isServer';

const { scope, prompt, cookiePolicy, api } = config.oauth.google;
const clientId = vars.get('G_CID');

function _appendGoogleAuthScript(onLoad) {
  if (!window.gapi) {
    appendScript(api, onLoad);
  } else {
    onLoad();
  }
}

function _initGoogleAuth(onLoad, hasErrored) {
  // Guard: don't attempt to create another instance if already initialized and not errored on init previously
  if (window.gapi && window.gapi.auth2.getAuthInstance() && !hasErrored) {
    onLoad();
    return;
  }

  window.gapi.auth2
    .init({
      client_id: clientId,
      cookiePolicy,
      scope,
    })
    .then(() => {
      onLoad();
    })
    .catch(() => {
      onLoad(true);
    });
}

export function setupGoogleAuth(onLoad, hasErrored) {
  // Google API library loads up and sets up the `auth2` set of functions for sign in.
  if (!isServer()) {
    _appendGoogleAuthScript(() => {
      window.gapi.load('auth2', () => _initGoogleAuth(onLoad, hasErrored));
    });
  }
}

export function doGoogleSignIn(actionHandler, source, partnerParams) {
  // window.Bridge.openGoogleAuth ensures this only happens on versions that have
  // this functionality available
  if (isDesktop() && window.Bridge && window.Bridge.openGoogleAuth) {
    invokeDesktopFunction(desktopFuncNames.openGoogleAuth);
    return;
  }

  window.gapi.auth2
    .getAuthInstance()
    .signIn({
      scope,
      prompt,
    })
    .then(
      (user) => {
        const id = user.getId();
        const idToken = user.getAuthResponse().id_token;

        actionHandler(
          accountCategory.labels.google,
          { userId: id, token: idToken },
          source,
          partnerParams,
        );
      },
      () => {
        // handle gracefully; not a req to log these as of yet
      },
    );
}

// Do this on logout so we can effectively kill the auth instance (thereby invalidating the
// listener)
export function doGoogleSignOut() {
  setupGoogleAuth(() => {
    try {
      window.gapi.auth2.getAuthInstance().signOut();
    } catch (e) {
      // noop
    }
  });
}

import { linkSonos } from '../../actions/partners';
import { selectTokenInfo } from '../../selectors/auth';
import { selectPartners } from '../../selectors/partners';
import vars from '../../vars';
import { hasPairAuthFlowParam } from '../auth/partnerAuthFlowHelpers';
import findPartnerInfo from '../findPartnerInfo';
import parseQuery from '../queryString/parse';
import fetchPartner from './fetchPartner';

export default function fetchPartnerAndPair({ location }) {
  return async (dispatch, getState) => {
    const query = parseQuery(location?.search);
    // Always check to fetch partner data
    await dispatch(
      fetchPartner({
        location: {
          ...location,
          query,
        },
      }),
    );

    // Only attempt to pair if user is in pairing flow
    if (hasPairAuthFlowParam(query)) {
      const partners = selectPartners(getState());
      const partnerInfo = findPartnerInfo(query, partners);
      const { partnerId } = partnerInfo;
      const isSonosDevice = partnerId === vars.get('SONOS_PARTNER_ID');

      if (isSonosDevice) {
        const tokenInfo = selectTokenInfo(getState());
        return dispatch(linkSonos(tokenInfo?.refreshToken, query?.state));
      }
    }

    return null;
  };
}

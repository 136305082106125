import isServer from '../isServer';
/**
 * - isDesktopServerFlag: set by query param dsktp=true
 *    when electron loads the website for use on the server
 * - Bridge: property is set on the window in the electron preload script and handlers
 *    can be added to it to allow the web code & desktop code to talk to each other
 * - Returning bool to initialize on the client
 **/
export default function initDesktop(isDesktopFlag) {
  if (!isServer()) {
    window.isElectron = () => isDesktopFlag && 'Bridge' in global.window;

    if (window.isElectron()) {
      return true;
    }
  }

  return false;
}

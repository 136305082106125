import querystring from 'querystring';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

// needed to support destinationInfo.attributes from API responses
// properties will be passed down to the API request for the page at guideUrl
export default function addAttributesParam(
  guideUrlObject,
  destinationInfoAttributes,
) {
  if (!isEmpty(destinationInfoAttributes)) {
    const queryObj = {
      ...guideUrlObject.query,
      attributes: querystring.stringify(destinationInfoAttributes),
    };

    return {
      ...guideUrlObject,
      query: queryObj,
      search: qs.stringify(queryObj),
    };
  }

  return guideUrlObject;
}

import each from 'lodash/each';
import filter from 'lodash/filter';
import toUpper from 'lodash/toUpper';
import uniq from 'lodash/uniq';

export const validSkuRegex = /^SKU-[\d]{8}$/i;

const zuoraSkuToRecurlyMap = {
  'SKU-00000012': '999_7days_monthly', // MONTHLY_SKU 12/16 (prod/qa)
  'SKU-00000016': '999_7days_monthly',
  'SKU-00000013': '9999_30days_yearly', // YEARLY_SKU 13/19
  'SKU-00000019': '9999_30days_yearly',
  'SKU-00000011': '4999_0days_yearly', // NFL_SEASON_PASS_SKU 11/17
  'SKU-00000017': '4999_0days_yearly',
  'SKU-00000022': '699_3days_monthly',
  'SKU-00000023': '6999_7days_yearly',
  'SKU-00000002': '499_7days_monthly',
  'SKU-00000010': '4999_30days_yearly',
};

export default function mapRecurlySkus(skuQuery) {
  const skuList = Array.isArray(skuQuery) ? skuQuery : [skuQuery];
  const zuoraSkus = filter(skuList, (sku) => validSkuRegex.test(sku));

  if (zuoraSkus.length > 0) {
    const recurlySkus = [];

    each(zuoraSkus, (sku) => {
      const mappedSku = zuoraSkuToRecurlyMap[toUpper(sku)];
      if (mappedSku) {
        recurlySkus.push(mappedSku);
      }
    });

    return uniq(recurlySkus);
  }

  return uniq(skuList);
}

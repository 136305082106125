export default function sortKeys(obj) {
  if (obj) {
    const sortedObj = {};

    Object.keys(obj)
      .sort()
      .map((key) => {
        sortedObj[key] = obj[key];

        return sortedObj;
      });

    return sortedObj;
  }

  return obj;
}

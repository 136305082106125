import url from 'url';
import get from 'lodash/get';
import omit from 'lodash/omit';
import addAttributesParam from '../addAttributesParam';

const sanitizeRegex = /[<>"]/g;
const searchPathname = '/search/';

export function escapeQuery(query) {
  return query.replace(sanitizeRegex, '');
}

export function getSearchRedirectLocationDescriptor(
  destinationInfo,
  itemToken,
) {
  const attributes = get(destinationInfo, 'attributes', {});
  const searchParam = attributes.query ? escapeQuery(attributes.query) : '';
  const search = itemToken
    ? `?query=${encodeURI(searchParam)}&itemtoken=${itemToken}`
    : `?query=${encodeURI(searchParam)}`;

  const urlObj = {
    pathname: searchPathname,
    query: {
      query: searchParam,
    },
    search,
  };

  if (itemToken) {
    urlObj.query.itemtoken = itemToken;
  }

  const filteredAttributes = omit(attributes, 'query');
  return addAttributesParam(urlObj, filteredAttributes);
}

export function getSearchRedirectUrl(destinationInfo, itemToken) {
  const locationDescriptor = getSearchRedirectLocationDescriptor(
    destinationInfo,
    itemToken,
  );

  return url.format(locationDescriptor);
}

export function getSearchUrl(query, itemToken) {
  return getSearchRedirectUrl(
    {
      attributes: {
        query,
      },
    },
    itemToken,
  );
}

export function getSearchLocationDescriptor(query) {
  return getSearchRedirectLocationDescriptor({
    attributes: {
      query,
    },
  });
}

import breakpoints from '../styles/breakpoints';
import isServer from './isServer';

export function isWithinRange(minWidth, maxWidth = Number.MAX_VALUE) {
  return isServer()
    ? false
    : window.innerWidth >= minWidth && window.innerWidth <= maxWidth;
}

export function isMinimumBreakpoint(newWidth, breakpoint) {
  return newWidth >= breakpoint;
}

export function isMin(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.MIN);
}

export function is360(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.BREAKPOINT_360);
}

export function isXXSmall(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.XXSMALL);
}

export function isXSmall(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.XSMALL);
}

export function isSmall(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.SMALL);
}

export function isMedium(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.MEDIUM);
}

export function isLarge(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.LARGE);
}

export function isXLarge(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.XLARGE);
}

export function isXXLarge(newWidth) {
  return isMinimumBreakpoint(newWidth, breakpoints.XXLARGE);
}

export default function filterProductsBySkus(products, skus) {
  const productsInSkuList = [];

  if (!skus || !skus.length) {
    return productsInSkuList;
  }

  skus.forEach((sku) => {
    if (products[sku]) {
      productsInSkuList.push(products[sku]);
    }
  });

  return productsInSkuList;
}

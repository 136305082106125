import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { apiSingleton } from '../../../client/api';
import { AB_TEST_IDS } from '../../constants/experiments';
import {
  adFormatNameMap,
  reportingAdFormats,
} from '../../constants/reporting/adDisplayFormats';
import { selectSessionId, selectUserIpAddress } from '../../selectors/app';
import { selectIsAuthenticated } from '../../selectors/auth';
import { selectExperiment } from '../../selectors/config';
import { selectUsername } from '../../selectors/me';
import {
  selectListenId,
  selectParentGuideId,
  selectTunedGuideId,
} from '../../selectors/player';

/**
  Creates the reporting context that every Unified Events API request should include
*/
export const updateReportingContext = createSelector(
  [
    selectIsAuthenticated,
    selectSessionId,
    selectUserIpAddress,
    selectListenId,
    selectTunedGuideId,
    selectParentGuideId,
    selectUsername,
    (state) => selectExperiment(state, AB_TEST_IDS, ''),
  ],
  (
    isAuthenticated,
    sessionId,
    ip = '',
    listenId,
    guideId = '',
    parentGuideId = 'null',
    userId = 'null',
    abTestIds,
  ) => {
    apiSingleton.instance.updateReportingContext({
      sessionId,
      ip,
      appState: {
        abTestIds,
      },
      listeningInfo: {
        listenId: listenId?.toString() || '',
        guideId,
        parentGuideId,
      },
      userInfo: {
        userId,
        isRegistered: isAuthenticated,
        isLoggedIn: isAuthenticated,
      },
    });
  },
);

/**
 * Returns dimensions value (array of reportingAdFormats enums || reportingAdFormats enum)...
 * to be sent as value of ad_format property in Unified Events API request data
 * @param dimensions {array} - can be an array of arrays (e.g [300, 250] || [[300, 250], [700, 200]])
 * @param returnArray {bool} optional - func will return enums in an array (returns single enum as string by default)
 * @param string (or array) {enum via reportingAdFormats}
 */
export function formatDimensions(dimensions, returnArray = false) {
  if (isEmpty(dimensions)) {
    return returnArray
      ? [reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED]
      : reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED;
  }

  if (returnArray) {
    return dimensions.reduce((acc, currentItem, index) => {
      const stringedDimension = currentItem.join('x');
      const dimensionEnum = adFormatNameMap[stringedDimension];

      if (dimensionEnum) {
        acc.push(dimensionEnum);
      }

      if (index === dimensions.length - 1 && isEmpty(acc)) {
        acc.push(reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED);
      }

      return acc;
    }, []);
  }

  if (
    dimensions.length === 2 &&
    typeof dimensions[0] === 'number' &&
    typeof dimensions[1] === 'number'
  ) {
    return adFormatNameMap[dimensions.join('x')];
  }
}

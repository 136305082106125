import { ImageOnly } from '@braze/web-sdk';
import { DEPLOYMENT_ENV, deploymentEnvs } from '../environment';

export const fieldNameMap = {
  container_type: 'containerLayout',
  container_title: 'containerTitle',
  card_type: 'itemLayout',
  card_title: 'title',
  card_subtitle: 'subtitle',
  card_priority: 'priority',
  screen_id: 'guideId',
  screen_location: 'index',
  id: 'brazeCardId',
};

export const brazeCardTypes = {
  imageOnly: ImageOnly.name,
};

export const fieldsWithNumberValue = [
  fieldNameMap.screen_location,
  fieldNameMap.card_priority,
];

export const containerViewModelsMap = {
  Carousel: 'Carousel',
  Gallery: 'Gallery',
  List: 'List',
};

export const childrenCanonicalModels = {
  BrickTile: 'BrickTile',
  Tile: 'Tile',
  SquareTile: 'SquareTile',
  LandscapeImage: 'LandscapeImage',
  Link: 'Link',
  EnhancedGame: 'EnhancedGame',
  Prompt: 'Prompt',
  ScheduleTile: 'ScheduleTile',
  CompactStatus: 'CompactStatus',
  PremiumTile: 'PremiumTile',
  WebPrompt: 'WebPrompt',
  WideTextTile: 'WideTextTile',
  NumberedItem: 'NumberedItem',
};

export const childrenViewModels = {
  BrickCell: 'BrickCell',
  TileCell: 'TileCell',
  BannerCell: 'BannerCell',
  Cell: 'Cell',
  UrlCell: 'UrlCell',
  EnhancedLiveGameCell: 'EnhancedLiveGameCell',
  CompactPrompt: 'CompactPrompt',
  CalendarCell: 'CalendarCell',
  CompactStatusCell: 'CompactStatusCell',
  WebCell: 'WebCell',
  WideTextTileCell: 'WideTextTileCell',
};

const childrenViewModelsToCanonicalMap = {
  [childrenViewModels.BrickCell]: childrenCanonicalModels.BrickTile,
  [childrenViewModels.TileCell]: childrenCanonicalModels.Tile,
  [childrenViewModels.BannerCell]: childrenCanonicalModels.LandscapeImage,
};

export const viewModelToCanonicalMap = {
  ...containerViewModelsMap,
  ...childrenViewModelsToCanonicalMap,
};

export const childrenCanonicalToViewModelsMap = {
  [childrenCanonicalModels.BrickTile]: childrenViewModels.BrickCell,
  [childrenCanonicalModels.Tile]: childrenViewModels.TileCell,
  [childrenCanonicalModels.SquareTile]: childrenViewModels.TileCell,
  [childrenCanonicalModels.LandscapeImage]: childrenViewModels.BannerCell,
  [childrenCanonicalModels.Link]: childrenViewModels.UrlCell,
  [childrenCanonicalModels.EnhancedGame]:
    childrenViewModels.EnhancedLiveGameCell,
  [childrenCanonicalModels.Prompt]: childrenViewModels.CompactPrompt,
  [childrenCanonicalModels.ScheduleTile]: childrenViewModels.CalendarCell,
  [childrenCanonicalModels.CompactStatus]: childrenViewModels.CompactStatusCell,
  [childrenCanonicalModels.PremiumTile]: childrenViewModels.Cell,
  [childrenCanonicalModels.WebPrompt]: childrenViewModels.WebCell,
  [childrenCanonicalModels.WideTextTile]: childrenViewModels.WideTextTileCell,
  [childrenCanonicalModels.NumberedItem]: childrenViewModels.Cell,
};

export const containerCardsTypesMap = {
  Gallery: [
    viewModelToCanonicalMap.BrickCell,
    viewModelToCanonicalMap.TileCell,
  ],
  Carousel: [viewModelToCanonicalMap.BannerCell],
};

export const layoutKeys = {
  [fieldNameMap.container_type]: true,
  [fieldNameMap.card_type]: true,
};

// card validation error codes
export const errorCodes = {
  missingScreenId: '1002',
  cardMismatch: '1004',
  emptyLocation: '1006',
  notSupportedCardTypeKeyValue: '1007',
  // When the card container position is at least 2 rows below of the current container at the very bottom of the page.
  outOfBounds: '1009',
  notSupportedContainerTypeKeyValue: '1010',
  notSupportedCardType: '1012',
  emptyCardImage: '1013',
  emptyCardDeeplink: '1014',
};

export const brazeContainerElementId = 'braze-container';

const brazeAppVersionEnvironmentMap = {
  // STAGE ENVS
  // these envs come first because new build versions appear here first
  [deploymentEnvs.qa]: 0,

  // follow by code in development
  [deploymentEnvs.local]: 1,
  [deploymentEnvs.pr]: 1,

  // followed by merged code, pending release
  [deploymentEnvs.dev]: 2,

  // ---------------------------------------------------

  // PROD ENVS
  // these envs come first because new build versions appear here first
  [deploymentEnvs.stage]: 0,
  [deploymentEnvs.itg]: 0,

  // followed by production
  [deploymentEnvs.production]: 1,
};
// i.e., 1.2.3.0, where the 4th "build" version represents a deployment environment from the list above
export const brazeAppVersionNumber = `${RELEASE_VERSION}.${
  brazeAppVersionEnvironmentMap[DEPLOYMENT_ENV] || 0
}`;
export const brazeAppVersion = `${RELEASE_VERSION}-${DEPLOYMENT_ENV}`;

import get from 'lodash/get';
import { doNotFetch } from '../../constants/locationState';
import { isProfile } from '../guideItemTypes';
import fetchProfileDispatcher from './dispatchers/fetchProfileDispatcher';
import setUpDirectToNowPlaying from './setUpDirectToNowPlaying';

export function fetchProfile(routeContextCreator, props) {
  if (get(props.location, ['state', doNotFetch])) {
    return null;
  }

  const guideItemRouteContext = routeContextCreator(props);

  if (guideItemRouteContext && isProfile(guideItemRouteContext.guideId)) {
    return async (dispatch, getState) => {
      const profileResponse = await fetchProfileDispatcher(
        dispatch,
        getState,
        guideItemRouteContext,
      );
      // NOTE: Must await setUpDirectToNowPlaying, otherwise server will render before D2NP data is returned
      await dispatch(setUpDirectToNowPlaying(guideItemRouteContext));
      return profileResponse;
    };
  }

  return null;
}

// set up with:
// createRadioProfileContext in ProfileConnector
// createVanityProfileContext in VanityProfileConnector
// getRadioContext in RadioRouteConnector and withGuideItemProfile
export default function setUpFetchProfile(routeContextCreator) {
  // As noted in the locationState constants, this flag indicates that a fetch should not be done
  // for a route/redirect. For example, when tuning to a station, if the Tune call contains an
  // instruction to redirect to a new profile, we actively perform a profiles API call to determine
  // the route for the profile page, and subsequently, commit that response to the store. This is
  // done in the nextTune action creator itself. Because we already performed an API call then,
  // we should not do so again, as it would skew analytics for profile views.
  //
  // NOTE: if we want to use this pattern for other situations, we should consider shifting this
  // logic to `src/client/utils/reloadOnPropsChange`, to make it more general.
  return (props) => fetchProfile(routeContextCreator, props);
}

import { isType } from '@tunein/web-common';
import some from 'lodash/some';
import {
  AUDIOBOOKS_BROWSE_PATH,
  BY_LANGUAGE_PATH,
  BY_LOCATION_PATH,
  HOME_BROWSE_PATH,
  I_HEART_PATH,
  MAP_VIEW,
  MUSIC_BROWSE_PATH,
  NEWS_BROWSE_PATH,
  PODCASTS_BROWSE_PATH,
  PREMIUM_BROWSE_PATH,
  RECENTS_PATH,
  SEARCH_PATH,
  SPORTS_BROWSE_PATH,
} from 'src/common/constants/paths';
import rootCategories from '../../data/rootCategories.json';
import { NOW_PLAYING_GUIDE_ID } from '../constants/dialogs/nowPlaying';
import vanityPathsToGuideIds from './guideItem/vanityPathsToGuideIds';
import keyMirror from './keyMirror';

// Matches strings where the first character is a letter, and everything after it is a number.
const validGuideIdRegex = /^[a-zA-Z]\d+$/;

export const behaviors = {
  browse: 'Browse',
  play: 'Play',
  profile: 'Profile',
  search: 'Search',
  subscribe: 'Subscribe',
  dismiss: 'Dismiss',
  signIn: 'SignIn',
  signUp: 'SignUp',
  link: 'Link',
  select: 'Select',
  interest: 'Interest',
};

// use for guide item API responses
export const types = {
  album: 'Album',
  artist: 'Artist',
  audioClip: 'AudioClip',
  category: 'Category',
  container: 'Container',
  link: 'link',
  program: 'Program',
  prompt: 'Prompt',
  station: 'Station',
  topic: 'Topic',
  user: 'User',
  region: 'Region',
};

export const guideIdTypePrefixMap = {
  affiliate: 'a',
  genre: 'g',
  language: 'l',
};

// maps guideId prefixes to API guide item types
export const guideIdPrefixTypeMap = {
  a: types.category,
  c: types.category,
  d: types.album,
  g: types.category,
  i: types.audioClip,
  m: types.artist,
  p: types.program,
  r: types.region,
  s: types.station,
  t: types.topic,
  u: types.user,
  v: types.category,
};

// use for program guide item API responses
export const programTypes = {
  audiobook: 'Audiobook',
  podcast: 'Podcast',
  game: 'Game',
  show: 'Show',
};

export const rootGenres = keyMirror('audiobooks', 'podcasts');

export const gameStatus = {
  expired: 'Expired',
  future: 'Future',
  live: 'Live',
};

export function isStation(guideIdOrSlug) {
  const guideId = vanityPathsToGuideIds[guideIdOrSlug] || guideIdOrSlug;
  return isType(guideId, types.station);
}

export function isProgram(guideId) {
  return isType(guideId, types.program);
}

export function isRootPath(guideId) {
  return some(
    rootCategories.guideIdsToPathnames,
    (value, key) => guideId === key,
  );
}

// TODO: Refactor so we have a single source of truth of the navigation items. It affects src/data/rootCategories.json and src/common/components/sidebar/NavigationMenu.js.
const rootPathsWithReporting = {
  [HOME_BROWSE_PATH]: true,
  [SEARCH_PATH]: true,
  [MAP_VIEW]: true,
  [RECENTS_PATH]: true,
  [BY_LOCATION_PATH]: true,
  [BY_LANGUAGE_PATH]: true,
  [I_HEART_PATH]: true,
  [PREMIUM_BROWSE_PATH]: true,
  [AUDIOBOOKS_BROWSE_PATH]: true,
  [MUSIC_BROWSE_PATH]: true,
  [SPORTS_BROWSE_PATH]: true,
  [NEWS_BROWSE_PATH]: true,
  [PODCASTS_BROWSE_PATH]: true,
};

export function isRootPathWithReporting(path) {
  return rootPathsWithReporting[path] || false;
}

export function isNowPlaying(guideId) {
  return guideId === NOW_PLAYING_GUIDE_ID;
}

export function isProfile(guideIdOrSlug) {
  const guideId = vanityPathsToGuideIds[guideIdOrSlug] || guideIdOrSlug;
  return isType(
    guideId,
    types.station,
    types.program,
    types.artist,
    types.album,
  );
}

export function isCategory(guideId) {
  return (
    isType(guideId, types.category, types.region) ||
    isRootPath(guideId) ||
    isNowPlaying(guideId)
  );
}

// this should be used to check if a BROWSE page is classified as podcast
export function isPodcast(rootGenre) {
  return rootGenre === rootGenres.podcasts;
}

// this should be used to check if a PROFILE is a podcast profile
export function isPodcastProfile(guideItem) {
  return (
    (
      guideItem?.contentType ||
      guideItem?.metadata?.properties?.program?.contentType ||
      ''
    ).toLowerCase() === programTypes.podcast.toLowerCase()
  );
}

export function isRegion(guideId) {
  // This should match 'regions' & 'r\d+'. But not 'recents'
  return (
    isType(guideId, types.region) ||
    guideId === rootCategories.guideIdsToPathnames.regions
  );
}

export function isLanguage(guideId) {
  return guideId === rootCategories.guideIdsToPathnames.languages;
}

export function isRootCategory(identifier) {
  return !!rootCategories.guideIdsToPathnames[identifier];
}

export function isGame(program = {}) {
  return program?.contentType === programTypes.game;
}

export function isShow(program = {}) {
  return program?.contentType === programTypes.show;
}

export function isExpiredGame(program = {}) {
  const isExpired = program?.eventStatus === gameStatus.expired;
  return isGame(program) && isExpired;
}

export function isRadioHome(guideId) {
  return guideId === rootCategories.guideIdsToPathnames.home;
}

/**
 * isValidGuideId - Use this to determine if an guide id conforms to the expected format, which
 * should be a letter, followed by numbers (only numbers). Examples:
 * s123456
 * t10393423908
 *
 * @param {string} guideId the identifier to check, which should conform to the format detailed above.
 * @return {boolean}
 */
export function isValidGuideId(guideId) {
  return validGuideIdRegex.test(guideId);
}

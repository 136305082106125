function equalsIgnoreCase(str1, str2) {
  if (str1 && str2) {
    return str1.toLowerCase() === str2.toLowerCase();
  }
  return false;
}

export default function containsIgnoreCase(array, element) {
  return array.some((ele) => equalsIgnoreCase(element, ele));
}

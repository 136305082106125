import eula from 'src/common/constants/analytics/categoryActionLabel/eula';

export function eulaMessageHandler({
  extras,
  actions,
  stateRef,
  brazeDocument,
  callbacks,
}) {
  const { type, version, acceptButtonSelector } = extras;

  actions.logEULAActivity(
    eula.subcategory.InAppConsent.name,
    eula.subcategory.InAppConsent.action.show.name,
    { loggedInValue: stateRef.current.isAuthenticated },
  );

  const acceptButton = brazeDocument?.querySelector?.(acceptButtonSelector);

  if (!acceptButton) return;

  acceptButton.addEventListener('click', (e) => {
    e.preventDefault();

    try {
      actions.addMeConsent({
        type,
        version,
      });

      callbacks.brazeLogAgreementConsent(version, type);

      actions.logEULAActivity(
        eula.subcategory.InAppConsent.name,
        eula.subcategory.InAppConsent.action.accept.name,
        {
          eulaVersion: version,
          eulaType: type,
        },
      );
    } catch (err) {
      // noop
    }

    callbacks.closeMessage();
  });
}

const inAppMessageHandlers = {
  consent: {
    eula: {
      messageHandler: eulaMessageHandler,
    },
  },
};

/**
 * `type` isn't always a static value that can be used to retrieve the correct message handler in inAppMessageHandlers.
 * We should use this util as a backup for those use cases, but try to enforce static values for future in-app messages.
 *
 * For example:
 * For EULA, `type` can be `eulaEEA` or `eulaROW`, but require the same message handler, so we have to check `type` for
 * the substring `eula` and derive a custom key that can be used to look up the message handler in inAppMessageHandlers.
 *
 * @param type - In-App Message metadata field defined here: https://tunein.atlassian.net/wiki/x/CwD7ng
 * @returns {string}
 */
function getDerivedTypeKey(type) {
  if (type?.includes('eula')) {
    return 'eula';
  }
}

export function getMessageConfig({ messageType, type } = {}) {
  const configs = inAppMessageHandlers[messageType];

  return configs?.[type] || configs?.[getDerivedTypeKey(type)] || {};
}

import { fetchMe } from '../../actions/me';
import getIsRenewalEligible from '../subscription/getIsRenewalEligible';
import fetchProducts from './fetchProducts';

export default function fetchMeAndProducts({ location }) {
  return async (dispatch, getState) => {
    await dispatch(fetchMe());

    const isRenewalEligible = getIsRenewalEligible(getState());

    return dispatch(fetchProducts(location, isRenewalEligible));
  };
}

import { fetchCategory as fetchCategoryAction } from '../../actions/browse';

import { isCategory } from '../guideItemTypes';

export function fetchCategory(props, routeContextCreator) {
  const guideItemRouteContext = routeContextCreator(props);
  if (!guideItemRouteContext) {
    return null;
  }
  const { guideId } = guideItemRouteContext;
  if (isCategory(guideId)) {
    return fetchCategoryAction(guideItemRouteContext);
  }
  return null;
}

// set up with:
// createRootCategoryContext in RootCategoryConnector
// getRadioContext in RadioRouteConnector and withGuideItemProfile
const setUpFetchCategory = (routeContextCreator) => (props) =>
  fetchCategory(props, routeContextCreator);

export default setUpFetchCategory;

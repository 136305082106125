import viewTypes from '../../constants/auth/viewTypes';

export default {
  isFetching: false, // TODO: obsolete when loader is fully centralized.
  authDialogOpen: false,
  viewType: viewTypes.signIn,
  actionNeedingAuth: null,
  failAuthNotice: '',
  successAuthNotice: '',
  signInOption: '',
};

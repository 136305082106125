import keyMirror from 'src/common/utils/keyMirror';

export const providers = keyMirror(
  'apple',
  'google',
  'recurly',
  'stripe',
  'other',
);

const apiProviderMap = {
  'apple.free': providers.apple,
  'apple.pro': providers.apple,
  'google.free': providers.google,
  'google.pro': providers.google,
  recurly: providers.recurly,
  stripe: providers.stripe,
};

export default function getSubscriptionProvider(apiProviderName) {
  return apiProviderMap[apiProviderName] || providers.other;
}

import { pairAuthFlows } from 'src/common/constants/auth/oauth';
import { SUBSCRIBE_PAIR_PATH } from 'src/common/constants/paths';
import { selectProductFromQuery } from 'src/common/utils/subscription/findProductHelpers';
import hasActiveSubscription from 'src/common/utils/subscription/hasActiveSubscription';
import { getOauthPartnerId, getPairAuthFlowParam } from '../queryParams';

export function hasPairAuthFlowParam(query) {
  return !!getPairAuthFlowParam(query);
}

export function isPairAuthFlowPin(query) {
  const pairAuthFlowParam = getPairAuthFlowParam(query);

  return !!(pairAuthFlowParam && pairAuthFlowParam === pairAuthFlows.pin);
}

export function isSubscribePairPath(pathname) {
  return pathname === SUBSCRIBE_PAIR_PATH;
}

/**
 * Checks if we should send user to /subscribepair page (CC Input Page in pairing Flow)
 * Returns true if user's account is eligible to sign up for a new web subscription
 * && A/B test isSubscriptionEnabled set to true
 * && their product sku supports /subscribepair
 * @param location {object}
 * @param isSubscriptionEnabled {boolean}
 * @param products {object}
 * @param canRenewOrUpdateSub {bool}
 * @param me {object}
 *
 */
export function shouldSendToSubscribePair(
  location,
  isSubscriptionEnabled,
  products,
  canRenewOrUpdateSub,
  me,
) {
  const selectedProduct = selectProductFromQuery(products, location);

  return (
    isSubscriptionEnabled &&
    !hasActiveSubscription(me) &&
    !canRenewOrUpdateSub &&
    !!selectedProduct?.sku
  );
}

// NOTE:
// The getPartnerParams helper is specifically for SI && SU auth requests
// However, if a user is in the automotive pairing flow, their partnerID & serial will represent the device being paired
// even though the auth request will come from mobile web
// On failed attempts, this was confusing platform && causing recaptcha issues
// To solve, when in the automotive pairing flow, we can avoid sending partnerId & serial in the auth requests
export function getPartnerParams(isPartner, query) {
  if (
    !isPartner ||
    [pairAuthFlows.pin, pairAuthFlows.qr].includes(getPairAuthFlowParam(query))
  )
    return {};

  return {
    partnerId: getOauthPartnerId(query),
    serial: query?.serial,
  };
}

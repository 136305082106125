import url from 'url';
import {
  getType as getGuideItemType,
  isAudioClip,
  isTopic,
} from '@tunein/web-common';
import get from 'lodash/get';
import { MISSING_GUIDE_ITEM_LINK_INFO } from '../../constants/errors';
import addAttributesParam from '../addAttributesParam';
import { isDiscordMode } from '../discord';
import {
  behaviors as GuideItemBehaviors,
  types as GuideItemTypes,
  rootGenres as RootGenreTypes,
  isRootCategory,
} from '../guideItemTypes';
import isServer from '../isServer';
import { getSearchRedirectUrl } from '../search/getSearchUrl';
import urlify from '../urlify';
import determineGuideItemPathname from './determineGuideItemPathname';
import {
  getPodcastPathnameFromDestinationObject,
  getPodcastPathnameFromGuideItem,
} from './getPodcastPathname';
import getRootCategorySlug from './getRootCategorySlug';
import { getCanonicalTitle } from './getTitleHelper';
import getVanitySlug from './getVanitySlug';

const urlPrefixes = {
  album: 'album',
  artist: 'artist',
  audiobooks: 'audio-books',
  radio: 'radio',
  search: 'search',
  user: 'user',
};

function determinePrefix(guideItem) {
  if (
    guideItem.rootGenre &&
    guideItem.rootGenre === RootGenreTypes.audiobooks
  ) {
    return urlPrefixes.audiobooks;
  }

  switch (guideItem.type) {
    case GuideItemTypes.album:
      return urlPrefixes.album;
    case GuideItemTypes.artist:
      return urlPrefixes.artist;
    case GuideItemTypes.user:
      return urlPrefixes.user;
    default:
      return urlPrefixes.radio;
  }
}

function setTopicQueryParam(guideItem) {
  const { guideId } = guideItem;

  if (guideId && isTopic(guideId) && !isDiscordMode()) {
    return {
      query: { topicId: guideId.slice(1) },
    };
  }

  return {};
}

function buildUrlObjWithDestInfo(destinationInfo = {}, guideItem = {}) {
  const { id, seoName, cleanPath } = destinationInfo;

  if (cleanPath) {
    return { pathname: cleanPath };
  }

  if (!id) {
    return null;
  }

  const slugPathname = getVanitySlug(id) || getRootCategorySlug(id);
  if (slugPathname) {
    return { pathname: urlify(slugPathname) };
  }

  const podcastPathname = getPodcastPathnameFromDestinationObject(
    destinationInfo,
    guideItem,
  );
  const path = determineGuideItemPathname(id, seoName, guideItem.type);
  const prefix = determinePrefix(guideItem);

  const urlObj = { ...setTopicQueryParam(guideItem) };

  urlObj.pathname = podcastPathname
    ? urlify(podcastPathname)
    : urlify(prefix, path);
  return addAttributesParam(urlObj, destinationInfo.attributes);
}

function buildUrlWithDestInfo(destinationInfo, guideItem) {
  if (destinationInfo?.cleanPath) {
    return destinationInfo.cleanPath;
  }

  const urlObj = buildUrlObjWithDestInfo(destinationInfo, guideItem);
  if (urlObj) {
    return url.format(urlObj);
  }
  return null;
}

export function userPathname(userName) {
  return urlify(urlPrefixes.user, userName);
}

function buildGuideItemPathname(guideItem) {
  const { guideId, type, properties, metadata } = guideItem || {};

  if (!guideId) {
    return null;
  }

  if (isAudioClip(guideId)) {
    return null;
  }

  const cleanPath =
    metadata?.properties?.seoInfo?.cleanPath ||
    properties?.seoInfo?.cleanPath ||
    '';

  if (cleanPath) {
    return cleanPath;
  }

  const slugPathname = getVanitySlug(guideId) || getRootCategorySlug(guideId);
  if (slugPathname) {
    return urlify(slugPathname);
  }

  const podcastPathname = getPodcastPathnameFromGuideItem(guideItem);
  if (podcastPathname) {
    return urlify(podcastPathname);
  }

  const title = getCanonicalTitle(guideItem);
  const path = determineGuideItemPathname(guideId, title, type);
  const prefix = determinePrefix(guideItem);

  return urlify(prefix, path);
}

// exported for unit testing
export function getParentDestinationInfo(guideItem, parentGuideItem) {
  const destinationInfo = get(
    guideItem,
    'properties.parentProgram.destinationInfo',
  );
  if (!destinationInfo) {
    return {};
  }
  if (!parentGuideItem) {
    return destinationInfo;
  }
  const parentSeoInfo = get(parentGuideItem, 'properties.seoInfo', {});

  // default to whatever SEOInfo is already in the destination info property
  return {
    ...parentSeoInfo,
    ...destinationInfo,
  };
}

function getPlayBehaviorPathname(guideItem, parentGuideItem) {
  // parentProgram will only be set for topics
  // fallback to profile action if it doesn't exist
  const parentDestInfo = getParentDestinationInfo(guideItem, parentGuideItem);
  const destInfo = get(guideItem.actions, 'profile.destinationInfo');

  return (
    buildUrlWithDestInfo(parentDestInfo, guideItem) ||
    buildUrlWithDestInfo(destInfo, guideItem)
  );
}

// TODO: make this private when all areas in the code utilize the getGuideItemPathnameWithLogger
// variant below.
export default function getGuideItemPathname(guideItem, parentGuideItem) {
  if (!guideItem) {
    return null;
  }

  /**
   * Happy Path - cleanPath exists
   * If cleanPath exists, we can return it as is
   * Depending on the type of guideItem, we may need to append a query param to the cleanPath (podcasts / audiobooks)
   * If cleanPath does not exist, we need to build the pathname based on the guideItem type as we have done for years (hopefully temporary)
   */
  const {
    type,
    guideId,
    properties: { parentProgram } = {},
  } = guideItem;
  const guideCleanPath = guideItem?.properties?.seoInfo?.cleanPath;
  const parentCleanPath = parentProgram?.destinationInfo?.cleanPath;

  if (type === 'Topic' && parentCleanPath && !isDiscordMode()) {
    return `${parentCleanPath}?topicId=${guideId.slice(1)}`;
  }

  if (guideCleanPath) {
    return guideCleanPath;
  }

  /**
   * Unhappy Path - cleanPath does not exist
   * Currently only known issue right now is Category "More" links
   */
  const behaviorAction = get(guideItem, 'behaviors.default.actionName');
  const searchDestInfo =
    get(guideItem, 'actions.searchLink.destinationInfo') ||
    get(guideItem, 'actions.search.destinationInfo');

  switch (behaviorAction) {
    case GuideItemBehaviors.browse:
      return buildUrlWithDestInfo(
        guideItem.actions.browse.destinationInfo,
        guideItem,
      );
    case GuideItemBehaviors.play:
      return getPlayBehaviorPathname(guideItem, parentGuideItem);
    case GuideItemBehaviors.profile:
      if (isTopic(guideItem.guideId)) {
        return buildUrlWithDestInfo(
          getParentDestinationInfo(guideItem),
          guideItem,
        );
      }

      return buildUrlWithDestInfo(
        guideItem.actions.profile.destinationInfo,
        guideItem,
      );
    case GuideItemBehaviors.search:
      return getSearchRedirectUrl(
        searchDestInfo,
        get(guideItem, 'context.token'),
      );
    case GuideItemBehaviors.subscribe:
      return null;
    default:
      return buildGuideItemPathname(guideItem);
  }
}

// TODO: make this private when all areas in the code utilize the getDestInfoUrlObjWithLogger
// variant below.
/**
 * getDestInfoUrlObj - creates a location descriptor from a navigation action (and optional
 * guide item if the action itself describes a redirect or navigation description for the guide item
 * itself).
 *
 * @param  {object} destinationInfo typically the destination info object for a navigation action.
 * @param  {object} guideItem = {}  optional guide item, if the destination info describes a
 *                                  a redirect action for the guide item itself.
 * @return {object} location descriptor object
 */
export function getDestInfoUrlObj(destinationInfo, guideItem = {}) {
  const { cleanPath } = destinationInfo;
  if (cleanPath) {
    return { pathname: cleanPath };
  }

  const type = isRootCategory(destinationInfo.id)
    ? GuideItemTypes.category
    : getGuideItemType(destinationInfo.id);

  return buildUrlObjWithDestInfo(destinationInfo, {
    ...guideItem,
    type,
  });
}

export function getRadioPathname(pathname) {
  return `/${urlPrefixes.radio}/${pathname}/`;
}

export function getGuideItemSubscribeSuccessRedirectLink(guideItem) {
  return guideItem?.actions?.subscribe?.successDeeplink || '';
}

function handleLinkBuilderError(logger, linkBuilder) {
  try {
    return linkBuilder();
  } catch (e) {
    if (e.message === MISSING_GUIDE_ITEM_LINK_INFO) {
      if (isServer()) {
        logger(MISSING_GUIDE_ITEM_LINK_INFO, e);
      } else {
        logger({
          message: MISSING_GUIDE_ITEM_LINK_INFO,
          context: { error: e },
        });
      }

      return null;
    }

    throw e;
  }
}

export function getGuideItemPathnameWithLogger(guideItem, parentGuideItem) {
  return (logger) =>
    handleLinkBuilderError(logger, () =>
      getGuideItemPathname(guideItem, parentGuideItem),
    );
}

export function getDestInfoUrlObjWithLogger(destinationInfo, guideItem) {
  return (logger) =>
    handleLinkBuilderError(logger, () =>
      getDestInfoUrlObj(destinationInfo, guideItem),
    );
}

export function buildGuideItemPathnameWithLogger(guideItem) {
  return (logger) =>
    handleLinkBuilderError(logger, () => buildGuideItemPathname(guideItem));
}

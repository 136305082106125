import { mapKeys, mapValues } from '@tunein/web-utils';
import {
  getContainerTemplate,
  getItemTemplate,
} from '../../../components/containerItems/utils/containerItemsTemplates';
import {
  brazeCardTypes,
  childrenCanonicalModels,
  containerCardsTypesMap,
  errorCodes,
  fieldNameMap,
  fieldsWithNumberValue,
  layoutKeys,
  viewModelToCanonicalMap,
} from '../constants';

let validationErrors;

// exported for testing
export function mapCards({
  extras,
  imageUrl,
  url,
  id,
  pinned,
  created,
  updated,
  constructor,
} = {}) {
  const customCard = {
    ...extras,
    imageUrl,
    url,
    id,
    pinned,
    created,
    updated,
    brazeCardType: constructor.name,
  };
  // format keys.
  const cardWithMappedKeys = mapKeys(
    customCard,
    (value, key) => fieldNameMap[key] || key,
  );

  // format values.
  return mapValues(cardWithMappedKeys, (value, key) => {
    if (fieldsWithNumberValue.includes(key)) {
      return value === '' ? value : Number(value);
    }

    return layoutKeys[key] ? viewModelToCanonicalMap[value] : value;
  });
}

// exported for testing
export function getValidationErrors(card, currentContainerItemsCount) {
  const {
    guideId,
    index,
    itemLayout,
    containerLayout,
    imageUrl,
    url,
    brazeCardType,
  } = card;
  const errors = [];

  if (!guideId) {
    errors.push(errorCodes.missingScreenId);
  }

  if (typeof index !== 'number') {
    errors.push(errorCodes.emptyLocation);
  }

  if (index > currentContainerItemsCount + 1) {
    errors.push(errorCodes.outOfBounds);
  }

  if (!itemLayout || !childrenCanonicalModels[itemLayout]) {
    errors.push(errorCodes.notSupportedCardTypeKeyValue);
  }

  if (!containerLayout || !containerCardsTypesMap[containerLayout]) {
    errors.push(errorCodes.notSupportedContainerTypeKeyValue);
  }

  if (
    containerLayout &&
    itemLayout &&
    !containerCardsTypesMap[containerLayout]?.includes(itemLayout)
  ) {
    errors.push(errorCodes.cardMismatch);
  }

  if (brazeCardType !== brazeCardTypes.imageOnly) {
    errors.push(errorCodes.notSupportedCardType);
  }

  if (!imageUrl) {
    errors.push(errorCodes.emptyCardImage);
  }

  if (!url) {
    errors.push(errorCodes.emptyCardDeeplink);
  }

  return errors.join(',');
}

function addValidationErrors(card, validationErrorCodes) {
  const { guideId, brazeCardId, index: screenLocation } = card;

  validationErrors?.push({
    id: `${guideId}-${brazeCardId}`,
    guideId,
    cardId: brazeCardId,
    errors: validationErrorCodes,
    index: screenLocation,
  });
}

function filterValidCards(card, currentContainerItemsCount) {
  const validationErrorCodes = getValidationErrors(
    card,
    currentContainerItemsCount,
  );

  if (!validationErrorCodes) {
    return true;
  }

  addValidationErrors(card, validationErrorCodes);

  if (validationErrorCodes === errorCodes.outOfBounds) {
    return true;
  }
}

// exported for testing
export function buildCards(containers, nextCard = {}, categorySeoInfo) {
  const {
    index,
    containerLayout,
    containerTitle,
    itemLayout,
    title,
    subtitle,
    imageUrl,
    priority,
    url,
    pinned,
    created,
    updated,
    brazeCardId,
    guideId,
  } = nextCard;
  let container = containers.find(
    (containerItem) => containerItem.index === index,
  );

  if (!container) {
    container = getContainerTemplate(index, containerLayout, containerTitle);
    containers.unshift(container);
  }

  container.children.push(
    getItemTemplate({
      priority,
      itemLayout,
      title,
      subtitle,
      imageUrl,
      url,
      pinned,
      created,
      updated,
      brazeCardId,
      screenId: guideId,
      categorySeoInfo,
      containerIndex: container.index,
      containerLayout,
      containerTitle,
    }),
  );

  container.uniqPairs = container.uniqPairs || {};
  const pairKey = `${containerLayout}:${itemLayout}`;
  container.uniqPairs[pairKey] = container.uniqPairs[pairKey]
    ? (container.uniqPairs[pairKey] += 1)
    : 1;

  return containers;
}

// exported for testing
export function sortCardsByPrioritization(a, b) {
  if ((a.pinned && b.pinned) || a.index === b.index) {
    return a.updated < b.updated ? 1 : -1;
  }

  if (a.pinned) {
    return -1;
  }

  if (b.pinned) {
    return 1;
  }

  return a.index - b.index;
}

// exported for testing
export function findCardTypeWithHighestPriority(mostPopularItemLayouts, cards) {
  const firstCard = mostPopularItemLayouts[0];

  if (mostPopularItemLayouts.length <= 1) {
    return firstCard;
  }

  const highestPriorityCard = cards
    .filter((card) =>
      mostPopularItemLayouts.includes(card.presentation?.layout),
    )
    .sort(sortCardsByPrioritization)
    .shift();

  return highestPriorityCard?.presentation?.layout || firstCard;
}

// exported for testing
export function filterMostPopularPairOfCards(container) {
  const { uniqPairs, children } = container;

  const mostRepeatedPairsCount = Object.values(uniqPairs).sort().pop();
  const mostPopularItemLayouts = Object.keys(uniqPairs)
    .filter((pair) => uniqPairs[pair] === mostRepeatedPairsCount)
    .map((pair) => pair.split(':').pop());
  const itemLayout = findCardTypeWithHighestPriority(
    mostPopularItemLayouts,
    children,
  );

  return {
    ...container,
    children: children
      .filter((cardItem) => {
        const isValidCard = cardItem.presentation?.layout === itemLayout;

        if (!isValidCard) {
          addValidationErrors(cardItem, errorCodes.cardMismatch);
        }

        return isValidCard;
      })
      .sort(sortCardsByPrioritization)
      .map((card, index) => ({ ...card, index })),
  };
}

// exported for testing
export function sortCardsContainersByIndex(a, b) {
  return a.index - b.index;
}

export function transformCards(
  cards,
  currentContainerItemsCount,
  categorySeoInfo,
) {
  validationErrors = [];
  const transformedCards = cards
    .map(mapCards)
    .filter((card) => filterValidCards(card, currentContainerItemsCount))
    .reduce(
      (containers, nextCard) =>
        buildCards(containers, nextCard, categorySeoInfo),
      [],
    )
    .map(filterMostPopularPairOfCards)
    .sort(sortCardsContainersByIndex);

  return { transformedCards, validationErrors };
}

import get from 'lodash/get';
import noop from 'lodash/noop';
// invokeDesktopFunction is a util to check that the function exists before invoking it.
// This is to protect the functions that are called
// via the desktop, that may not be released yet, or may be removed.
// This will help keep the desktop functionality backwards compatible.
export default function invokeDesktopFunction(funcName, ...args) {
  const bridgeFunc = get(window, ['Bridge', funcName], noop);
  return bridgeFunc(...args);
}

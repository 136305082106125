import noop from 'lodash/noop';
import { mintSingleton } from '../../../client/mint';
import { tunerSingleton } from '../../../client/tuner';
import { MIDROLL_SLOT_NAME } from '../../components/ads/constants/displayAds';
import { playerStatuses } from '../../constants/playerStatuses';

class MidrollScheduler {
  #breaksPerSession;

  #remainingMidrollBreaks;

  #midrollInterval;

  #countdownToNextMidroll;

  #intervalId;

  #guideId;

  #isActive = false;

  #isPlayingMidroll = false;

  #isPaused = false;

  get isActive() {
    return this.#isActive;
  }

  get isPaused() {
    return this.#isPaused;
  }

  get isPlayingMidroll() {
    return this.#isPlayingMidroll;
  }

  async #playMidroll() {
    this.#isPlayingMidroll = true;
    await mintSingleton.instance?.preload();
    await mintSingleton.instance?.requestSlot(MIDROLL_SLOT_NAME).catch(noop);
    this.#isPlayingMidroll = false;
  }

  async #onMidrollInterval() {
    if (--this.#countdownToNextMidroll) {
      return;
    }

    clearInterval(this.#intervalId);
    await this.#playMidroll();
    this.#countdownToNextMidroll = this.#midrollInterval;

    // if the tuner state is not paused, this indicates that the midroll did not load/play
    // so we should manually reschedule the next midroll here. Otherwise, we can resume playback.
    if (tunerSingleton.instance?.state.name === playerStatuses.paused) {
      tunerSingleton.instance?.play();
    } else {
      this.scheduleNextMidroll(this.#guideId);
    }
  }

  #startInterval() {
    // note: there seems to be a bug in Jest's (as of 29.2.2) fake timers that breaks timer-running operations
    // after setInterval's first interval if the handler is an async function. Wrapping the handler in an arrow
    // func to work around this bug for testing purposes.
    this.#intervalId = setInterval(() => this.#onMidrollInterval(), 1000);
  }

  init({ midrollBreaksPerSession, midrollIntervalSeconds }) {
    this.#breaksPerSession =
      midrollBreaksPerSession ?? Number.POSITIVE_INFINITY;
    this.#remainingMidrollBreaks = this.#breaksPerSession;
    this.#midrollInterval = midrollIntervalSeconds || 30 * 60; // default: 30 minutes
    this.#countdownToNextMidroll = this.#midrollInterval;
  }

  isNewGuideId(guideId) {
    return guideId !== this.#guideId;
  }

  pause() {
    clearInterval(this.#intervalId);
    this.#isPaused = true;
  }

  resume() {
    this.#startInterval();
    this.#isPaused = false;
  }

  reset() {
    clearTimeout(this.#intervalId);
    this.#isActive = false;
    this.#guideId = null;
    this.#remainingMidrollBreaks = this.#breaksPerSession;
    this.#countdownToNextMidroll = this.#midrollInterval;
  }

  scheduleNextMidroll(guideId) {
    if (!this.#guideId) {
      this.#guideId = guideId;
    }

    if (!this.#remainingMidrollBreaks--) {
      this.reset();
      return;
    }

    this.#isActive = true;
    this.#startInterval();
  }
}

export default new MidrollScheduler();

import { selectAdsTargeting } from '../../selectors/config';
import { canShowPersonalizedAds } from './canShowPersonalizedAds';

export function getAdsTargeting(state) {
  const adsTargeting = selectAdsTargeting(state);

  if (!adsTargeting) {
    return {};
  }

  return {
    ...adsTargeting,
    ppid: canShowPersonalizedAds(state) ? adsTargeting.ppid : '',
  };
}

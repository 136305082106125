import vars from '../vars';
import keyMirror from './keyMirror';

// buildTypes signify the webpack build config used (NODE_ENV)
const buildType = vars.get('NODE_ENV');
const buildTypes = keyMirror('development', 'production');

// deploymentEnvs signify the deployment environment (TI_ENV)
export const DEPLOYMENT_ENV = vars.get('TI_ENV');
export const deploymentEnvs = keyMirror(
  'local',
  'experimental',
  'pr', // pull request
  'development',
  'qa',
  'stage',
  'production',
);

// represents the Platform services environment (i.e., development, stage, production)
export const PLATFORM_ENV = vars.get('PLATFORM_ENV');

// build types
export const isDevBuild = () => buildType === buildTypes.development;

// deployment environments
export const isLocalEnv = () => DEPLOYMENT_ENV === deploymentEnvs.local;
export const isPullRequestEnv = () => DEPLOYMENT_ENV === deploymentEnvs.pr;
export const isProdEnv = () => DEPLOYMENT_ENV === deploymentEnvs.production;
export const isStageBetaEnv = () => DEPLOYMENT_ENV === deploymentEnvs.stage;
export const isExperimentalEnv = () =>
  DEPLOYMENT_ENV === deploymentEnvs.experimental;

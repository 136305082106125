import config from 'src/common/config';
import isWindowsDesktop from 'src/common/utils/userAgent/isWindowsDesktop';

export default function getConfigStreamFormats(
  isMobile,
  isDesktop,
  isDiscord,
  userAgent,
) {
  let formats = config.streamFormats;

  if (isMobile) {
    formats = config.mobileStreamFormats;
  }

  if (isDesktop) {
    formats = config.desktopStreamFormats;
  }

  if (isDiscord) {
    formats = config.discordStreamFormats;
  }

  if (!isWindowsDesktop(userAgent)) {
    formats = formats.replace(',wma', '');
  }

  return formats;
}

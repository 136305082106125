import { selectSubscriptionStatus } from '../../selectors/me';
import { premiumSubscriptionStatus } from './subscriptionStatus';

export default function hasActiveSubscription(me) {
  const meStatus = selectSubscriptionStatus({ me });

  if (!meStatus) {
    return false;
  }

  // active subscription (any provider)
  if (meStatus === premiumSubscriptionStatus.active) {
    return true;
  }

  return false;
}

export default function formatClientErrorData(errorData = {}) {
  const { error = {} } = errorData.context || {};

  if (error instanceof Error) {
    // needed because `stack` isn't an enumerable property and will get dropped when stringified to JSON
    errorData.context.error = { ...error, stack: error.stack }; // eslint-disable-line no-param-reassign
  }

  return errorData;
}

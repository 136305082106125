import isServer from '../isServer';

// Taken from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
export default function isLocalStorageAvailable() {
  if (isServer()) {
    return false;
  }

  const x = '__storage_test__';

  try {
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

// Note: Most of the code in this file is temporal, once we know we go with Freestar solution only then we'll optimize an existing ads config which will include the Freestar slots in it, so we'll keep only the freestarSlotNames & freestarSlotNameAttributesMap in this file.

// List of all currently supported dispaly ad slot names. We utilize these when calling a Gpt API.
export const gptSlotNames = {
  browse_top: 'browse_top',
  browse_mid: 'browse_mid',
  browse_side: 'browse_side',
  profile_top: 'profile_top',
  profile_mid: 'profile_mid',
  profile_side: 'profile_side',
  bottom_banner: 'bottom_banner',
  view_model_ads: 'view_model_ads',
  view_model_ads_mobile: 'view_model_ads_mobile',
  nowPlaying_side: 'nowPlaying_side',
};

// List of all new dispaly ad slot names supported by Freestar. We utilize these when calling a Freestar API.
const freestarSlotNames = {
  tunein_browse_top: 'tunein_browse_top',
  tunein_browse_mid: 'tunein_browse_mid',
  tunein_browse_side: 'tunein_browse_side',
  tunein_profile_top: 'tunein_profile_top',
  tunein_profile_mid: 'tunein_profile_mid',
  tunein_profile_side: 'tunein_profile_side',
  tunein_bottom_banner: 'tunein_bottom_banner',
  tunein_view_model_ads: 'tunein_view_model_ads',
  tunein_view_model_ads_mobile: 'tunein_view_model_ads_mobile',
  tunein_nowplaying_side: 'tunein_nowplaying_side',
};

const gptSlotNameAttributesMap = {
  [gptSlotNames.browse_top]: {
    id: 'browse_top_small',
    slotName: gptSlotNames.browse_top,
  },
  [gptSlotNames.browse_mid]: {
    id: 'browse_mid_min',
    slotName: gptSlotNames.browse_mid,
  },
  [gptSlotNames.browse_side]: {
    id: 'browse_banner_right_side',
    slotName: gptSlotNames.browse_side,
  },
  [gptSlotNames.profile_top]: {
    id: gptSlotNames.profile_top,
    slotName: gptSlotNames.profile_top,
  },
  [gptSlotNames.profile_mid]: {
    id: gptSlotNames.profile_mid,
    slotName: gptSlotNames.profile_mid,
  },
  [gptSlotNames.profile_side]: {
    id: gptSlotNames.profile_side,
    slotName: gptSlotNames.profile_side,
  },
  [gptSlotNames.view_model_ads]: {
    id: gptSlotNames.view_model_ads,
    slotName: gptSlotNames.view_model_ads,
  },
  [gptSlotNames.view_model_ads_mobile]: {
    id: gptSlotNames.view_model_ads_mobile,
    slotName: gptSlotNames.view_model_ads_mobile,
  },
  [gptSlotNames.nowPlaying_side]: {
    id: gptSlotNames.nowPlaying_side,
    slotName: gptSlotNames.nowPlaying_side,
  },
  [gptSlotNames.bottom_banner]: {
    id: gptSlotNames.bottom_banner,
    slotName: gptSlotNames.bottom_banner,
  },
};

const freestarSlotNameAttributesMap = {
  [freestarSlotNames.tunein_browse_top]: {
    id: freestarSlotNames.tunein_browse_top,
    slotName: freestarSlotNames.tunein_browse_top,
    placementAttributes: {
      'data-freestar-ad': '__320x50 __728x90',
    },
  },
  [freestarSlotNames.tunein_browse_mid]: {
    id: freestarSlotNames.tunein_browse_mid,
    slotName: freestarSlotNames.tunein_browse_mid,
    placementAttributes: {
      'data-freestar-ad': '__320x50',
    },
  },
  [freestarSlotNames.tunein_browse_side]: {
    id: freestarSlotNames.tunein_browse_side,
    slotName: freestarSlotNames.tunein_browse_side,
    placementAttributes: {
      // [note]: This slot supports both 300x250, 300x600 sizes(can been seen in our doc - https://docs.google.com/spreadsheets/d/1QbKhTjlr9z47uO_jGs15n-jG0AmvZ4d_U2FCIzmbomI/edit?usp=sharing), but Freestar lists the largest size within the ad request. It's purpose mostly being to prevent CLS. Despite this naming, all sizes within the mapping will be requested. There're others slots defined in this map with the same idea.
      'data-freestar-ad': '__300x600',
    },
  },
  [freestarSlotNames.tunein_profile_top]: {
    id: freestarSlotNames.tunein_profile_top,
    slotName: freestarSlotNames.tunein_profile_top,
    placementAttributes: {
      'data-freestar-ad': '__970x250',
    },
  },
  [freestarSlotNames.tunein_profile_mid]: {
    id: freestarSlotNames.tunein_profile_mid,
    slotName: freestarSlotNames.tunein_profile_mid,
    placementAttributes: {
      'data-freestar-ad': '__320x50',
    },
  },
  [freestarSlotNames.tunein_profile_side]: {
    id: freestarSlotNames.tunein_profile_side,
    slotName: freestarSlotNames.tunein_profile_side,
    placementAttributes: {
      'data-freestar-ad': '__300x600',
    },
  },
  [freestarSlotNames.tunein_view_model_ads]: {
    id: freestarSlotNames.tunein_view_model_ads,
    slotName: freestarSlotNames.tunein_view_model_ads,
    placementAttributes: {
      'data-freestar-ad': '__728x90',
    },
  },
  [freestarSlotNames.tunein_view_model_ads_mobile]: {
    id: freestarSlotNames.tunein_view_model_ads_mobile,
    slotName: freestarSlotNames.tunein_view_model_ads_mobile,
    placementAttributes: {
      'data-freestar-ad': '__300x250',
    },
  },
  [freestarSlotNames.tunein_nowplaying_side]: {
    id: freestarSlotNames.tunein_nowplaying_side,
    slotName: freestarSlotNames.tunein_nowplaying_side,
    placementAttributes: {
      'data-freestar-ad': '__300x250 __300x600',
    },
  },
  [freestarSlotNames.tunein_bottom_banner]: {
    id: freestarSlotNames.tunein_bottom_banner,
    slotName: freestarSlotNames.tunein_bottom_banner,
    placementAttributes: {
      'data-freestar-ad': '__970x250', // When changing data-freestar-ad dimensions - check max height attribute for bottom-banner
    },
  },
};

// Maps GPT with Freestar display ad slots.
export const gptFreestarSlotsMap = {
  [gptSlotNames.browse_top]: freestarSlotNames.tunein_browse_top,
  [gptSlotNames.browse_mid]: freestarSlotNames.tunein_browse_mid,
  [gptSlotNames.browse_side]: freestarSlotNames.tunein_browse_side,
  [gptSlotNames.profile_top]: freestarSlotNames.tunein_profile_top,
  [gptSlotNames.profile_mid]: freestarSlotNames.tunein_profile_mid,
  [gptSlotNames.profile_side]: freestarSlotNames.tunein_profile_side,
  [gptSlotNames.bottom_banner]: freestarSlotNames.tunein_bottom_banner,
  [gptSlotNames.view_model_ads]: freestarSlotNames.tunein_view_model_ads,
  [gptSlotNames.view_model_ads_mobile]:
    freestarSlotNames.tunein_view_model_ads_mobile,
  [gptSlotNames.nowPlaying_side]: freestarSlotNames.tunein_nowplaying_side,
};

// Returns the slot attributes to render the ad considering if a Freestar or Gpt is enabled.
export const getDisplayAdAttributes = (
  slotName,
  isFreestarEnabled,
  slotIdOverwritten,
) => {
  return {
    ...(isFreestarEnabled
      ? freestarSlotNameAttributesMap[gptFreestarSlotsMap[slotName]]
      : gptSlotNameAttributesMap[slotName]),
    ...(slotIdOverwritten && { id: slotIdOverwritten }),
  };
};

const oldAdUnitsMREC = [
  '/15480783/Gemini/browse_side',
  '/15480783/Gemini/profile_side',
];
const oldAdUnitsBanner = [
  '/15480783/Gemini/browse_top',
  '/15480783/Gemini/mobile-web_320x50',
  '/15480783/Gemini/profile_top',
  '/15480783/web_desktopweb/Display_Main_1',
  '/15480783/MOB_MobileWeb/Display_Main_1',
];
export const oldAdUnits = oldAdUnitsMREC.concat(oldAdUnitsBanner);

export const getNewAdUnitId = (oldAdUnitId, adsPartnerAlias, name) => {
  if (oldAdUnitsMREC.includes(oldAdUnitId)) {
    return `/15480783/Display/${adsPartnerAlias}/MREC`;
  }

  if (oldAdUnitsBanner.includes(oldAdUnitId)) {
    // some placements of the nowPlaying_side slot are set to the legacy /15480783/MOB_MobileWeb/Display_Main_1 ad unit id, but it's an MREC slot
    return name === gptSlotNames.nowPlaying_side
      ? `/15480783/Display/${adsPartnerAlias}/MREC`
      : `/15480783/Display/${adsPartnerAlias}/banner`;
  }

  return oldAdUnitId;
};

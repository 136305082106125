import { pick } from '@tunein/web-utils';
import removeElementById from 'src/common/utils/dom/removeElementById';
import vars from '../../vars.manifest.json';
import isServer from './utils/isServer';

const envVars = {};

const valAliases = {
  true: true,
  false: false,
  null: null,
  undefined: undefined, // eslint-disable-line object-shorthand
};

function deStringify(val) {
  if (valAliases.hasOwnProperty(val)) {
    // hardcoded value
    return valAliases[val];
  }
  if (!Number.isNaN(val) && +val <= Number.MAX_SAFE_INTEGER) {
    // number :: http://stackoverflow.com/a/175787
    return +val;
  }
  if (!val) {
    return null;
  }
  return val; // string
}

function loadOnServer() {
  let allEnvVarsSet = true;
  const names = [...vars.server, ...vars.universal];

  names.forEach((name) => {
    if (process.env.hasOwnProperty(name)) {
      envVars[name] = deStringify(process.env[name]);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${name} env var is not set`);
      allEnvVarsSet = false;
    }
  });

  if (!allEnvVarsSet) {
    throw new Error('Missing env vars');
  }
}

// loadOnClient expects server-side rendering
// to have hardcoded a script tag in the HTML response.
// this tag should contain a global variable (`VARS`)
// exposing universal env vars.
function loadOnClient() {
  Object.assign(envVars, window.VARS);
  delete window.VARS;
  removeElementById('varsMarkupEl');
}

if (isServer()) {
  loadOnServer();
} else {
  loadOnClient();
}

function get(name) {
  return envVars[name];
}

function getClientVars() {
  return pick(envVars, vars.universal);
}

export default {
  get,
  getClientVars,
};

export * from 'src/common/constants/envVars';

import { isType } from '@tunein/web-common';
import get from 'lodash/get';
import { types } from '../guideItemTypes';
import determineGuideItemPathname from './determineGuideItemPathname';
import { getCanonicalTitle } from './getTitleHelper';
import scrubSeoInfo from './scrubSeoInfo';

const SPECIAL_CHARACTERS_EXCEPT_SLASH = /[^\w()\s\-_/]/g;

const SEO_PATH_AT_PROPS = 'properties.seoInfo.path';
const SEO_PATH_AT_METADATA = 'metadata.properties.seoInfo.path';

// See the confluence doc linked to ticket TUNE-9627 for the spec
// about seo podcast url title character count.
const SEO_TITLE_CHARACTER_COUNT = 50;

function checkPodcastPath(model, pathToProp) {
  if (!pathToProp) {
    return null;
  }
  const path = get(model, pathToProp, '');
  return path.indexOf('podcast') >= 0 ? path : null;
}

function getPodcastPrefix(guideItem) {
  return (
    checkPodcastPath(guideItem, SEO_PATH_AT_PROPS) ||
    checkPodcastPath(guideItem, SEO_PATH_AT_METADATA)
  );
}

export function getPodcastPathnameFromGuideItem(guideItem) {
  const { guideId, properties } = guideItem;
  if (!guideId) {
    return null;
  }
  const podcastPrefixWithGenre = getPodcastPrefix(guideItem);
  if (!podcastPrefixWithGenre) {
    return null;
  }
  // clean up SEOPath with included primaryGenre, making sure to leave '/'
  // if there is one
  const pathname = scrubSeoInfo(
    podcastPrefixWithGenre,
    SPECIAL_CHARACTERS_EXCEPT_SLASH,
  );

  // handle if pathname is just 'podcasts'
  if (pathname.indexOf('/') < 0) {
    return isType(guideId, types.topic)
      ? `${pathname}/${
          properties?.parentProgram?.guideId || properties?.seoInfo?.guideId
        }`
      : `${pathname}/${guideId}`;
  }
  // build url 'podcasts/{primaryGenre}-{guideId}' for categories
  if (isType(guideId, types.category)) {
    return `${pathname}-${guideId}`;
  }
  // build url 'podcasts/{primaryGenre}/{title}-{guideId} for programs
  const title = getCanonicalTitle(guideItem);
  if (isType(guideId, types.program) && title) {
    // cap the title at 50 characters
    const cappedTitle = title.substring(0, SEO_TITLE_CHARACTER_COUNT);
    return `${pathname}/${determineGuideItemPathname(guideId, cappedTitle)}`;
  }

  return null;
}

export function getPodcastPathnameFromDestinationObject(
  destinationObject,
  guideItem,
) {
  // check to see if we can build a podcast url using the metadata on the actual guideItem
  const podcastUrlFromGuideItem = getPodcastPathnameFromGuideItem(guideItem);
  if (podcastUrlFromGuideItem) {
    return podcastUrlFromGuideItem;
  }
  // only need to do the following checks for topic guide items
  if (!isType(guideItem.guideId, types.topic)) {
    return null;
  }
  // check if the destination object contains a podcast path property
  const podcastPath = checkPodcastPath(destinationObject, 'path');
  const { title, id } = destinationObject || {};
  if (!podcastPath || !title || !id) {
    return null;
  }
  // build url in relation to parent program: 'podcasts/{primaryGenre}/{title}-{guideId}
  const pathname = scrubSeoInfo(podcastPath, SPECIAL_CHARACTERS_EXCEPT_SLASH);
  const cappedTitle = title.substring(0, SEO_TITLE_CHARACTER_COUNT);
  return `${pathname}/${determineGuideItemPathname(id, cappedTitle)}`;
}

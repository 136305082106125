import has from 'lodash/has';

const couponTypeStorePath = 'subscription.coupon.type';
/**
 * hasValidCouponData - checks if coupon data is loaded and valid, by simply checking on the
 *                      presence of `type`.
 *
 * @param  {type} state    redux state to drill into to check for valid coupon data.
 * @return {type}          boolean value, to signify if the coupon data is valid or not.
 */
export default function hasValidCouponData(state) {
  return has(state, couponTypeStorePath);
}

import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';

function utmPredicate(value, key) {
  return /^utm_/.test(key);
}

export function excludeUtmParams(queryParams) {
  return omitBy(queryParams, utmPredicate);
}

export function extractUtmParams(queryParams) {
  return pickBy(queryParams, utmPredicate);
}

export function excludeUtmParamsFromUrl(url) {
  if (url) {
    return url
      .replace(/utm_[^&]+&?/g, '') // clean all utm params
      .replace(/&$|\?$/, ''); // kill any trailing `&` or `?`
  }

  return url;
}

// clearing search so that url.format doesn't ignore query
// this prevents any other things that we added to query like paymentflow=true to disappear
// see the usage of this in getLoginRedirect.js
// see url.format here: https://www.npmjs.com/package/url
export function excludeUtmParamsFromLocationDescriptor(locationDescriptor) {
  return {
    ...locationDescriptor,
    query: excludeUtmParams(locationDescriptor.query),
    search: '',
  };
}

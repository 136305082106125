// Named after: https://github.com/ReactTraining/react-router/blob/v2.8.1/docs/Glossary.md#locationdescriptor
import url from 'url';
import qs from 'qs';

export default function getLoginLocationDescriptor(location, viewType) {
  const redirectLocation =
    location.pathname === '/login/'
      ? location.query.redirect
      : url.format(location);

  const queryObject = {
    redirect: redirectLocation,
    vt: viewType,
  };

  return {
    pathname: '/login/',
    query: queryObject,
    search: qs.stringify(queryObject),
  };
}

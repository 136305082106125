import get from 'lodash/get';
import set from 'lodash/set';
import { fetchCategoryEffect } from '../../../actions/browse';
import { fetchProfile } from '../../../actions/profile';
import { RECOMMENDED_CONTENT_CATEGORY } from '../../../constants/profile';
import { shouldFetchRecommendedContent } from '../../profile';

export default async function fetchProfileDispatcher(
  dispatch,
  getState,
  guideItem,
) {
  const { guideId } = guideItem;
  const state = getState();
  let categoryToPrepend;

  if (shouldFetchRecommendedContent(guideId, state)) {
    // eslint-disable-next-line no-unused-expressions
    await dispatch(
      fetchCategoryEffect(RECOMMENDED_CONTENT_CATEGORY, {
        guideId: RECOMMENDED_CONTENT_CATEGORY,
        attributes: { context: guideId },
      }),
    );

    categoryToPrepend = get(
      getState().categories, // calling getState() again to obtain updated state from category fetch
      [RECOMMENDED_CONTENT_CATEGORY, 'containerItems', '0'],
    );

    if (categoryToPrepend) {
      set(categoryToPrepend, 'presentation.layout', 'Carousel');
    }
  }

  return dispatch(fetchProfile(guideItem, categoryToPrepend));
}

import {
  number as creditCard,
  expirationDate,
  postalCode,
} from 'card-validator';
import indexOf from 'lodash/indexOf';
import trim from 'lodash/trim';
import * as Validation from 'src/common/utils/form/validation';
import isNumeric from 'validator/lib/isNumeric';

const paymentRequiredFields = [
  'firstName',
  'lastName',
  'addressOne',
  'city',
  'postalCode',
];

const nameLengthError = 'Name is too long';
export const invalidCardDateErrorText = 'Invalid expiration date';
export const invalidCardNumberErrorText = 'Invalid card number';
export const invalidCvvErrorText = 'Invalid cvv';

export const invalidPostalCodeErrorText = 'Invalid postal code';
export const chooseLocationText = 'Please select your billing country';
export const manualStateAndCountryText =
  'Please select your billing country and state';
export const mustBeLoggedIn = 'Must be logged in to perform this action.';

export function addNameLengthError(values, field, errors = {}) {
  let newErrors = errors;
  const valueEntered = values[field];

  if (!valueEntered) {
    return newErrors;
  }

  if (valueEntered.length > 20) {
    newErrors = Validation.addError(nameLengthError, field, errors);
  }
  return newErrors;
}

function addCardExpirationDateError(values, field, errors = {}) {
  let newErrors = errors;
  const dateEntered = values[field];

  if (!dateEntered) {
    return newErrors;
  }

  const validationObject = expirationDate(dateEntered);

  if (validationObject.isValid && validationObject.year.length < 2) {
    newErrors = Validation.addError(invalidCardDateErrorText, field, errors);
  } else if (!validationObject.isValid) {
    newErrors = Validation.addError(invalidCardDateErrorText, field, errors);
  }
  return newErrors;
}

function addCvvError(values, field, errors = {}) {
  let newErrors = errors;
  const cvv = values[field];

  if (cvv && (!isNumeric(cvv) || cvv.length < 3 || cvv.length > 4)) {
    newErrors = Validation.addError(invalidCvvErrorText, field, errors);
  }

  return newErrors;
}

function addPostalCodeError(values, field, errors = {}) {
  let newErrors = errors;
  const enteredPostal = values[field];

  if (trim(enteredPostal)) {
    const validationObject = postalCode(enteredPostal);

    if (!validationObject.isValid) {
      newErrors = Validation.addError(
        invalidPostalCodeErrorText,
        field,
        errors,
      );
    }
  }

  return newErrors;
}

function isAcceptedCardType(type) {
  const validCardTypes = ['visa', 'mastercard', 'american-express', 'discover'];

  if (!type) {
    return false;
  }

  return type ? indexOf(validCardTypes, type) !== -1 : false;
}

function addCardNumberError(values, field, errors = {}) {
  let newErrors = errors;
  const numberEntered = values[field];

  if (!numberEntered) {
    return newErrors;
  }

  const validationObject = creditCard(numberEntered);

  if (
    validationObject.isValid &&
    !isAcceptedCardType(validationObject.card.type)
  ) {
    newErrors = Validation.addError(invalidCardNumberErrorText, field, errors);
  } else if (!validationObject.isValid) {
    newErrors = Validation.addError(invalidCardNumberErrorText, field, errors);
  }

  return newErrors;
}

function getCardTypeValue(cardNumber) {
  const cardTypeMap = {
    visa: 'Visa',
    discover: 'Discover',
    'master-card': 'MasterCard',
    'american-express': 'AmericanExpress',
  };

  const validObj = creditCard(cardNumber);

  return validObj.isValid ? cardTypeMap[validObj.card.type] : '';
}

export function getCardData(cardNumber, expDate) {
  const validExpirationDate = expirationDate(expDate);

  return {
    cardType: getCardTypeValue(cardNumber),
    cardNumber: cardNumber ? cardNumber.replace(/-|\s/g, '') : '',
    expirationMonth: validExpirationDate.month,
    expirationYear: `20${validExpirationDate.year}`,
  };
}

export function addPaymentErrors(values, props) {
  if (props?.isStripeEnabled) {
    return [];
  }
  let errors = Validation.addRequiredFields(values, paymentRequiredFields);
  errors = addNameLengthError(values, 'firstName', errors);
  errors = addNameLengthError(values, 'lastName', errors);
  errors = addCardNumberError(values, 'cardNumber', errors);
  errors = addCardExpirationDateError(values, 'expirationDate', errors);
  errors = addPostalCodeError(values, 'postalCode', errors);
  errors = addCvvError(values, 'cvv', errors);
  return errors;
}

import vars from '../vars';
import isServer from './isServer';

/**
 * Wrapper around global site tag (gtag.js) API. Documentation here:
 *  https://developers.google.com/gtagjs/reference/api
 */

// exported for unit testing
export function gtagWrapper(...parameters) {
  if (isServer() || !window.gtag) {
    return;
  }
  try {
    window.gtag(...parameters);
  } catch (ex) {
    // noop
  }
}

function event(eventName, params = {}) {
  gtagWrapper('event', eventName, params);
}

function reportConversion(params) {
  event('conversion', params);
}

function mobileWebInstallClick() {
  reportConversion({ send_to: vars.get('G_MW_INSTALL_CONVERSION_ID') });
}

function mobileWebBlockerDismiss() {
  reportConversion({ send_to: vars.get('G_MW_DISMISS_CONVERSION_ID') });
}

function branchInstallClick() {
  reportConversion({ send_to: vars.get('G_BRANCH_CONVERSION_ID') });
}

function profilePlayButtonClick() {
  reportConversion({ send_to: vars.get('G_PROFILE_PLAY_CONVERSION_ID') });
}

export default {
  mobileWebInstallClick,
  mobileWebBlockerDismiss,
  branchInstallClick,
  profilePlayButtonClick,
};

import { containerLayouts } from 'src/common/constants/guideItem';
import {
  selectBreadcrumbId,
  selectPageLoadId,
} from 'src/common/selectors/reporting';
import {
  childrenCanonicalToViewModelsMap,
  childrenViewModels,
  containerViewModelsMap,
} from 'src/common/utils/braze/constants';

// exported for testing
export function getContainerType(container) {
  if (!container) {
    return '';
  }
  const { type } = container;
  if (!type || type === containerLayouts.Locked) {
    return containerViewModelsMap.List;
  }
  return type;
}

/**
  Constructs the content reporting data for Unified Events(CONTENT_IMPRESSION, CONTENT_CLICKED).
*/
export function getContentEventData({
  guideItem,
  state,
  includeActionType = false,
}) {
  const pageLoadId = selectPageLoadId(state);
  const breadcrumbId = selectBreadcrumbId(state);

  const {
    guideId,
    brazeCardId,
    presentation = {},
    index,
    reporting = {},
    behaviors = {},
    actions = {},
  } = guideItem;
  const { page, container } = reporting;

  // Note: By historical reasons we don't always receive the container and/or item presentation.layout for Lists from API, so when reporting we fallback to 'List' for the container and to 'Cell' for the item as it's exactly the types we'd receive in View Model response.

  return {
    breadcrumbId,
    pageLoadId,
    pageGuideId: page?.guideId || '',
    pageAliasName: page?.aliasName || '',
    containerId: container?.id || '',
    containerName: container?.name || '',
    containerType: getContainerType(container),
    containerPosition: container?.position || 0,
    itemId: brazeCardId || guideId || '',
    itemType:
      childrenCanonicalToViewModelsMap[presentation.layout] ||
      presentation.layout ||
      childrenViewModels.Cell,
    itemPosition: brazeCardId ? index : index - 1 || 0,
    ...(includeActionType && {
      actionType:
        behaviors.default?.actionName ||
        behaviors.primaryButton?.actionName ||
        actions.navigate?.destinations?.[0]?.destination ||
        '',
    }),
  };
}

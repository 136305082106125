import { pick } from '@tunein/web-utils';
import { COUPON_PATH } from 'src/common/constants/paths';
import { COUPON_FLOW } from 'src/common/constants/subscription/flow';
import parseQuery from '../queryString/parse';
import hasActiveSubscription from './hasActiveSubscription';

export default function getCouponRedirect(me, location) {
  const hasActiveSub = hasActiveSubscription(me);
  const query = parseQuery(location?.search || '');
  const inCouponSubFlow = query[COUPON_FLOW];

  if (hasActiveSub && inCouponSubFlow) {
    return {
      pathname: COUPON_PATH,
      query: pick(query, ['source']),
    };
  }

  return undefined;
}

import { selectCoupon } from '../../selectors/subscription';
import hasValidCouponData from './hasValidCouponData';
import isCouponFlow from './isCouponFlow';

export default function isCouponValidForSku(state, location, sku) {
  const coupon = selectCoupon(state);
  const isValidForSku =
    coupon?.appliesToAllPlans || coupon?.plans?.includes(sku);
  return (
    hasValidCouponData(state) &&
    (isCouponFlow(location) || !!coupon?.isProductDefaultCoupon) &&
    isValidForSku
  );
}

import get from 'lodash/get';

/*
  the `match` prop from react-router seems to only be reliable on the route
  level and must be used wherever data needs to be fetched before rendering.

  matchUrl is set at the AppConnector route level component with
  `withCurrentRouteCustomProps`. It is being used to listen to when the
  route location changes because match does not update reliably in nested
  components.
*/
export default function didRouteChange(prevProps, currentProps) {
  return (
    get(prevProps, 'routeProps.matchUrl') !==
    get(currentProps, 'routeProps.matchUrl')
  );
}

import find from 'lodash/find';
import get from 'lodash/get';

const findProductWithSameBillingPeriod = (
  productArray,
  productWithBillingPeriod,
) =>
  find(
    productArray,
    (currentProd) =>
      currentProd.billingPeriod ===
      get(productWithBillingPeriod, 'billingPeriod'),
  );

export default findProductWithSameBillingPeriod;

import { playerStatuses } from '../../constants/playerStatuses';
import { selectIsDiscord, selectIsMobile } from '../../selectors/app';
import { selectIsUserSubscribed } from '../../selectors/me';
import {
  selectAutoPlayReady,
  selectHasAdBlocker,
  selectIsIdle,
  selectPlayerStatus,
  selectTunedGuideId,
} from '../../selectors/player';

const adBlockerPlayerStatuses = {
  [playerStatuses.connecting]: true,
  [playerStatuses.failed]: true,
};

function shouldShowPlayerForAdBlockerCheck(state) {
  if (selectIsUserSubscribed(state) || selectHasAdBlocker(state) === false) {
    return false;
  }

  return !!adBlockerPlayerStatuses[selectPlayerStatus(state)];
}

const shouldShowPlayer = (state) =>
  !selectIsIdle(state) ||
  selectAutoPlayReady(state) ||
  selectTunedGuideId(state) ||
  // Player should always show on Discord desktop
  (selectIsDiscord(state) && !selectIsMobile(state)) ||
  shouldShowPlayerForAdBlockerCheck(state);

export default shouldShowPlayer;

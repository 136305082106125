/**
 * We are managing two different user subscription states in gemini-web:
 *
 *  1) The Premium Subscription state, which is dictated by Platform
 *  2) The state of the subscription determined by our subscription
 *     service provider (recurly)
 *
 * Most of the subscription behavior found on gemini-web is based on our own
 * Premium Subscription states. This is fine for the most part since our
 * Premium Subscription states generally mirror our service provider's subscription
 * states.
 *
 * The only difference between the two is the concept of a 'canceled' state in which the
 * user is considered to be 'active' in our Premium Subscription, but 'active and needing to renew'
 * in our subscription service provider's state. We need to incorporate this additional state
 * into our own App state in order to determine UI on the Account Settings page, and when pushing
 * 'canceled' users over into the gemini-web renew flow. To accomplish this, while still maintaining
 * separation between the two states, we source any Premium Subscription state information from the
 * redux store's me.details property, and any data pertaining to the provider's subscription state
 * from the subscription.current property.
 *
 * Redux-Store: {
 *    me: {
 *      details: { [Premium Subscription Data]...},
 *    }
 *    subscription: {
 *      current: { [Subscription Provider Data]...},
 *    }
 * }
 */
import keyMirror from '../keyMirror';

export const premiumSubscriptionStatus = keyMirror(
  'unknown',
  'none',
  'active',
  'expired',
  'temporarilylocked',
  'suspended',
);

export const currentSubscriptionStatus = {
  unknown: 'unknown',
  none: 'none',
  active: 'active',
  canceled: 'canceled',
  expired: 'expired',
  trialing: 'trialing',
};
